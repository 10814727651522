import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridComponent } from './ag-grid/ag-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormateCurrencyComponent } from './ag-grid/formate-currency/formate-currency.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RemoteGridBindingDirective } from './ag-grid/remote-grid-binding.directive';
import { EditButtonComponent } from './ag-grid/edit-button/edit-button.component';
import { RouterModule } from '@angular/router';
import { ViewButtonComponent } from './ag-grid/view-button/view-button.component';
import { DeleteButtonComponent } from './ag-grid/delete-button/delete-button.component';

@NgModule({
  declarations: [AgGridComponent, FormateCurrencyComponent, RemoteGridBindingDirective, EditButtonComponent, ViewButtonComponent, DeleteButtonComponent],
  imports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [AgGridComponent, FormateCurrencyComponent, RemoteGridBindingDirective, EditButtonComponent]
})
export class SheardGridModule { }
