import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-case-task-list',
  templateUrl: './case-task-list.component.html',
  styleUrls: ['./case-task-list.component.css']
})
export class CaseTaskListComponent implements OnInit {
  @ViewChild('TaskStatus') TaskStatus: any;
  getDataTask: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  id: any;
  saveFormTaskStatus: any;
  isValidFormSubmittedTaskStatus!: boolean;
  user: any;
  roledata: any;
  districts: any;
  constructor(
    private toast: ToasterService,
    private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.taskData();
    this.onforminitTaskStatus();
  }

  taskData() {
    this.MasterS.getCaseTaskData().subscribe((data: any) => {

      this.getDataTask = data.resultData;
      this.dtTrigger.next();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.MasterS.deleteCaseTask(id).subscribe(
      (data: any) => {
        this.MasterS.getCaseTaskData()

        // window.location.href = '/case-task-list';
        this.router.navigate(['/dashboard//case-task-list'])
      },

    );
  }

  showModalTask(ids: number) {
    this.id = ids;


    // this.largeModal2.show();
  }

  onforminitTaskStatus() {
    this.saveFormTaskStatus = this.fb.group({
      status: [''],
    });
  }

  clickFunctionStatusOfTask() {

    this.isValidFormSubmittedTaskStatus = false;
    if (this.saveFormTaskStatus.invalid) {

      this.isValidFormSubmittedTaskStatus = true;
    } else {

      this.MasterS
        .updateCaseTask(this.saveFormTaskStatus.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been Updated.'
          );
          // window.location.href = '/case-task-list';
          this.router.navigate(['/dashboard/case-task-list'])
          // this.saveFormTaskStatus.reset()
          // this.TaskStatus.nativeElement.click();
        });

    }


  }
}
