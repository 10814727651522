import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-councel-update',
  templateUrl: './councel-update.component.html',
  styleUrls: ['./councel-update.component.css']
})
export class CouncelUpdateComponent implements OnInit {
  id: any;
  EditData: any;
  saveForm!: FormGroup;
  isValidFormSubmitted!: boolean;

  constructor(
    private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      coun_name: ['', Validators.required],
      coun_mobile: ['', Validators.pattern('^[0-9][0-9]{9}$')],
      coun_email: ['', Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')],
    })
    this.MasterS
      .editCouncel(this.id)
      .subscribe((data: any) => {
        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
        this.spinner.hide();
      })
  }

  onforminit(userData: any) {
    this.saveForm.patchValue(userData)
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.MasterS
        .updateCouncel(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          // window.location.href = '/counsel-list';
          this._location.back();

          // this.router.navigate(['/dashboard/counsel-list']);
        });
    }
  }

  get f() {
    return this.saveForm.controls;
  }

}
