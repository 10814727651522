import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-task-type-list',
  templateUrl: './task-type-list.component.html',
  styleUrls: ['./task-type-list.component.css']
})
export class TaskTypeListComponent implements OnInit {


  getData: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  districts: any;
  roledata: any;
  user: any;

  constructor(
    private MasterS: MasterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.viewdata();
  }

  viewdata() {
    this.MasterS.getTaskType().subscribe((data: any) => {

      this.getData = data.resultData;
      this.dtTrigger.next();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.MasterS.deleteTaskType(id).subscribe(
      (data: any) => {
        this.MasterS.getTaskType()

        // window.location.href = '/task-type-list';
        this.router.navigate(['/dashboard/task-type-list'])
      },

    );
  }

}

