import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-counsel-list',
  templateUrl: './counsel-list.component.html',
  styleUrls: ['./counsel-list.component.css']
})
export class CounselListComponent implements OnInit {
  getDataCounsel: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  user: any;
  roledata: any;
  districts: any;
  constructor(
    private router: Router,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.counselData();
  }
  counselData() {
    this.spinner.show();
    this.MasterS.GetCounseldata().subscribe((data: any) => {

      this.getDataCounsel = data.resultData;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }

  deleteUser(id: number) {

    this.MasterS.deleteCouncel(id).subscribe(
      (data: any) => {
        alert(" Data has been deleted.");
        this.MasterS.GetCounseldata();

        this.router.navigate(['/dashboard/counsel-list'])
      },

    );
  }
}
