<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="bg-blue lter b-b wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h3 class="m-n font-medium">Case Document List View</h3>
        </div>
        <div class="col-sm-6 text-right poppins hidden-xs">

        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">
                <div class="row" style="margin-top: 1rem; margin-bottom: .5rem;">

                </div>
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th class="text-center">#</th>
                      <th>Case No.</th>
                      <th>Document Type</th>
                      <th>Document Name</th>
                      <th>Created By</th>
                      <!-- <th>Updated By</th>
                        <th>Date Added</th> -->
                      <th>Attach Document</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of getDataDocument; let i= index;">

                      <td class="text-center">{{ [i+1] }}</td>
                      <td>{{data?.caseData[0]?.case_no}}</td>
                      <td>{{data?.case_doc_type}}</td>
                      <td>{{data?.case_doc_name}}</td>
                      <!-- <td>{{data?.case_doc_attached_by}}</td>
                          <td>{{data?.case_doc_updated_by}}</td> -->
                      <td>{{data?.created_at | date}}</td>
                      <td *ngIf="this.data?.case_document">
                        <a href="https://lcms.evalue8.info/{{this.data?.case_document}}" target="_blank"
                          rel="noopener noreferrer" class="btn btn-primary btn-sm
                            "> <i class="fa fa-eye"></i> View Document
                        </a>
                      </td>
                      <td *ngIf="!this.data?.case_document"></td>
                      <td class="text-center">
                        <div class="btn-group dropdown">
                          <button class="btn btn-default w-xxs btn-sm r-2x" data-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-cog g-px-5  text-info text-sm"></i> <span class=""><i
                                class="fa fa-caret-down"></i></span></button>
                          <ul class="dropdown-menu">
                            <li *ngIf="roledata.caseDocumentEdit
                                    "><a routerLink="/dashboard/case-document-update/{{data?._id}}"
                                routerLinkActive="active"><i class="fa fa-edit"></i> Edit
                              </a></li>
                            <li *ngIf="roledata.caseDocumentDelete
                                    "><a (click)="deleteUser(data?._id)"><i class="fa fa-trash-o"></i> Delete </a></li>

                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>