<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script>
    function validateForm() {

        let x = document.forms["myForm"]["g-recaptcha-response"].value;
        if (x == "") {
            alert("Captcha must be filled out");
            return false;
        }
    }
</script>

<!-- content1 -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Hold on while we take you to your LCMS Account! </p>
</ngx-spinner>

<section class="main-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="food-img-bg">
                    <div class="top-logo">
                        <img src="./assets/img/logo.png" alt="" class="Legal case Management Information System">
                    </div>
                    <div class="main-bg-con">
                        <div class="row g-0">
                            <div class="col-lg-8">
                                <div class="left-content">
                                    <h3>Welcome to<br> Court Case management System
                                        <!-- <p style="display: block; font-size: 3rem; margin: 5px 0px;">Beneficiary &
                                            Budget Management Information System</p> -->
                                    </h3>
                                    <span>न्यायालय वाद प्रबंधन प्रणाली</span>
                                    <div class="clearfix"></div>
                                    <div class="Bottom-Content" style="margin-top:3rem;">
                                        <p>Court Case Management Information System is a system designed, developed and
                                            implemented
                                            for managing the end-to-end legal cases and monitoring system for the
                                            Department.</p>
                                        <p>Track all the cases of multiple courts at one place and view all the case
                                            hearing schedules and case history at the click of a button.</p>


                                        <div class="footerSec">
                                            <div class="copyrightline">
                                                <p>© 2023-24 | All Right Reserved | Version 1.2.3 | Last Update {{lastUpdated}}</p>
                                                <!-- <h5>Implemented By:</h5> -->
                                            </div>

                                            <div class="footer_img">
                                                <img src="./assets/img/flg.jpg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="login-sec">
                                    <h2>Forgot Password</h2>
                                    <p>Enter your registered e-mail to receive instructions to reset your password.</p>
                                    <div class="login-sec-con">
                                        <form [formGroup]="appForm" method="post" (ngSubmit)="clickFunction()">
                                            <div class="">
                                                <div class="form-group">
                                                    <input type="text" class="form-control user-bg" id="uername"
                                                        placeholder="Email" formControlName="email">
                                                </div>
                                            </div>
                                            <div *ngIf="f.email.errors && isValidFormSubmitted"
                                                class="text-danger text-left mb-1 errorcontrols">
                                                <div *ngIf="f.email.errors.required"><small>Email is required</small>
                                                </div>

                                            </div>
                                            <!-- <div class="">
                                                <div class="form-group">
                                                    <input type="password" class="form-control user-password" id=""
                                                        placeholder="Password" formControlName="password">
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-md-12">
                                                <div class="form-group">
                                                    <re-captcha siteKey="6LcZYWYeAAAAAFFzV_pFdZBksDadFNggfpxTZMfS">
                                                    </re-captcha>
                                                </div>

                                            </div> -->
                                            <!-- <span class="msg-error error text-danger">hello</span> -->

                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-lg btn-block login_btn"
                                                    id="btn-validate">Verify</button>
                                            </div>

                                            <!-- <div>
                                                <a href="forgot.html" class="forget_password">Forgot
                                                    Password?</a>
                                            </div> -->

                                            <div class="clearfix"></div>

                                            <!-- <p class="Create_Account">New to CII FACE Assessment Portal?<a href="/signUp"> Sign Up</a>
                </p> -->
                                            <div class="captcha-text">
                                                <p>Protected by reCAPTCHA. Google <a
                                                        href="https://policies.google.com/privacy"
                                                        target="_blank">Privacy Policy</a> & <a
                                                        href="https://policies.google.com/terms" target="_blank">Terms
                                                        of
                                                        Service</a> apply.</p>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>