<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <!-- main header -->
        <div class="lter b-b py-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <h3 class="m-n font-medium"> Court Wise Report</h3>
                </div>
                <div class="col-sm-6 text-right poppins hidden-xs">

                </div>
            </div>
        </div>
        <!-- / main header -->
        <div class="row mw">
            <div class="col-md-12">
                <div class="card no-shadow">
                    <div class="tab-content bg-seashell">
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">

                            </div>
                        </div>
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">
                                <app-ag-grid [isExpoartCsv]="true" [userList]="getDataVal" [ids]="'farmer-netural'"
                                    [colDefs]="colDefs" [paginationPageSize]="12"
                                    [remoteGridBinding]="remoteGridBinding"></app-ag-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>