<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <!-- main header -->
        <div class="lter b-b py-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <h4 class="m-n font-thin text-white">विभिन्न न्यायालयों में लम्बित संगठनवार वादों की अद्दतन
                        स्थिति (a-1)
                    </h4>
                </div>
                <div class="col-sm-6 text-right poppins hidden-xs">
                    <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
                    <!-- <a href="/add-case" class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" ><i
                  class="fa fa-plus"></i> Add  Case </a> -->
                    <!-- </button> -->
                    <!-- <button data-toggle="modal" data-target="#basicModal" type="submit" class="btn btn-sm text-10 btn-addNew m-r-xs "> Import</button> -->
                </div>
            </div>
        </div>
        <!-- / main header -->
        <div class="row mw">
            <div class="col-md-12">
                <div class="card no-shadow">
                    <div class="tab-content bg-seashell">
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">

                                <div class="row">

                                    <div class="col-md-4">
                                        <div class="input_grp form-group" style="display: flex;">
                                            <div>
                                                <label for="">Department / विभाग</label>
                                                <form [formGroup]="form" style="width: 42rem;">
                                                    <mat-form-field appearance="fill" class="field-css">
                                                        <mat-select formControlName="case_petitioner_id"
                                                            (selectionChange)="selectMultiDepartment($event)" multiple>
                                                            <mat-option *ngFor="let data of getDataO"
                                                                [value]="data._id">{{ data.org_name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </form>

                                            </div>




                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2"></div> -->
                                    <div class="col-md-4">
                                        <div class="input_grp form-group">
                                            <label for="">Court / मा0 न्यायालय <span
                                                    class="text-danger">*</span></label>
                                            <select class="form-control select-language form-control-sm validation"
                                                (change)="selectCourt($event)">
                                                <option selected [value]='court_id'>मा0 उच्च न्यायालय लखनऊ</option>
                                                <option *ngFor="let data of getDataC" value={{data._id}}>
                                                    {{data.court_name}}
                                                </option>

                                            </select>

                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <span class="input-group-btn" (click)="getAOne()">
                                            <button style="margin-left:34px" class="btn btn-default" type="button">
                                                Submit
                                            </button>
                                        </span>
                                    </div>
                                    <div class="col-md-2">
                                        <span class="input-group-btn">
                                            <button (click)="goToPrintPage()" style="margin-left:34px"
                                                class="btn btn-default" type="button">
                                                Print
                                                <!-- [routerLink]="['/order']" [queryParams]="{ category: 'watches'}" -->
                                            </button>
                                        </span>
                                    </div>


                                </div>


                            </div>
                        </div>
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs table-responsive">
                                <table class="table bg-white newTable ">
                                    <thead id="myHeader">
                                        <!-- <tr>
                                            <th colspan="32" class="text-center"> मासिक सूचना प्रपत्र- अ-1
                                            </th>
                                        </tr> -->
                                        <tr>

                                            <th colspan="32" class="text-center" style="padding: 0px;line-height: 1;">
                                                <div style="display: flex; justify-content: space-around;">
                                                    <h6>मासिक सूचना प्रपत्र- अ-1</h6>
                                                    <h6>
                                                        सिंचाई विभाग उत्तर प्रदेश
                                                        लखनऊ <br />
                                                        विभिन्न न्यायालयों में लम्बित संगठनवार वादों की अद्दतन स्थिति
                                                         
                                                    </h6>   
                                                </div>
                                                                               
                                            </th>
                                        </tr>
                                        <tr>
                                            <th rowspan="3">क्र0 सं0</th>
                                            <th rowspan="3">संगठन का नाम</th>
                                            <th colspan="4">{{court_name}}</th>
                                            <th colspan="26">शासन से सम्बन्धित अनुभाग</th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2" width="53">कुल वाद</th>
                                            <th rowspan="2">दाखिल </th>
                                            <th rowspan="2">शेष</th>
                                            <th rowspan="2" width="44">आ0<br />
                                                न0</th>
                                            <th colspan="2">अनु0-01</th>
                                            <th colspan="2">अनु0-02</th>
                                            <th colspan="2">अनु0-03</th>
                                            <th colspan="2">अनु0-04</th>
                                            <th colspan="2">अनु0-05</th>
                                            <th colspan="2">अनु0-06</th>
                                            <th colspan="2">अनु0-07</th>
                                            <th colspan="2">अनु0-08</th>
                                            <th colspan="2">अनु0-09</th>
                                            <th colspan="2">अनु0-10</th>
                                            <th colspan="2">अनु0-11</th>
                                            <th colspan="2">अनु0-12</th>
                                            <th colspan="2">अनु0-13</th>
                                        </tr>
                                        <tr>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                            <th>कुल</th>
                                            <th>शेष</th>
                                        </tr>
                                        <tr>
                                            <th>1</th>
                                            <th>2</th>
                                            <th>3</th>
                                            <th>4</th>
                                            <th>5</th>
                                            <th>6</th>
                                            <th>7</th>
                                            <th>8</th>
                                            <th>9</th>
                                            <th>10</th>
                                            <th>11</th>
                                            <th>12</th>
                                            <th>13</th>
                                            <th>14</th>
                                            <th>15</th>
                                            <th>16</th>
                                            <th>17</th>
                                            <th>18</th>
                                            <th>19</th>
                                            <th>20</th>
                                            <th>21</th>
                                            <th>22</th>
                                            <th>23</th>
                                            <th>24</th>
                                            <th>25</th>
                                            <th>26</th>
                                            <th>27</th>
                                            <th>28</th>
                                            <th>29</th>
                                            <th>30</th>
                                            <th>31</th>
                                            <th>32</th>
                                        </tr>
                                    </thead>
                                    <tbody class="content">

                                        <ng-container *ngIf="uniqueOrg.length>0">
                                            <ng-container *ngFor="let get of uniqueOrg;let i=index">
                                                <tr>
                                                    <td colspan="32" width="1640">{{get.org_name}}</td>
                                                </tr>
                                                <ng-container *ngFor="let case of get;let i=index">
                                                    <tr *ngIf="case.sub_org_name">
                                                        <td>{{i+1}}</td>
                                                        <td>{{case.sub_org_name}}</td>
                                                        <td>&nbsp;{{case.kul_wad}}
                                                        </td>
                                                        <td>&nbsp;{{case.dakhil}}</td>
                                                        <td>&nbsp;{{case.sesh}}</td>
                                                        <td>&nbsp;{{case.not_required}}</td>

                                                        <td>&nbsp;{{case.anu_1}}</td>
                                                        <td>&nbsp;{{case.ses_1}}</td>

                                                        <td>&nbsp;{{case.anu_2}}</td>
                                                        <td>&nbsp;{{case.ses_2}}</td>
                                                        <td>&nbsp;{{case.anu_3}}</td>
                                                        <td>&nbsp;{{case.ses_3}}</td>
                                                        <td>&nbsp;{{case.anu_4}}</td>
                                                        <td>&nbsp;{{case.ses_4}}</td>
                                                        <td>&nbsp;{{case.anu_5}}</td>
                                                        <td>&nbsp;{{case.ses_5}}</td>
                                                        <td>&nbsp;{{case.anu_6}}</td>
                                                        <td>&nbsp;{{case.ses_6}}</td>
                                                        <td>&nbsp;{{case.anu_7}}</td>
                                                        <td>&nbsp;{{case.ses_7}}</td>
                                                        <td>&nbsp;{{case.anu_8}}</td>
                                                        <td>&nbsp;{{case.ses_8}}</td>
                                                        <td>&nbsp;{{case.anu_9}}</td>
                                                        <td>&nbsp;{{case.ses_9}}</td>
                                                        <td>&nbsp;{{case.anu_10}}</td>
                                                        <td>&nbsp;{{case.ses_10}}</td>
                                                        <td>&nbsp;{{case.anu_11}}</td>
                                                        <td>&nbsp;{{case.ses_11}}</td>
                                                        <td>&nbsp;{{case.anu_12}}</td>
                                                        <td>&nbsp;{{case.ses_12}}</td>
                                                        <td>&nbsp;{{case.anu_13}}</td>
                                                        <td>&nbsp;{{case.ses_13}}</td>

                                                        <td>&nbsp;</td>
                                                    </tr>
                                                </ng-container>
                                                <tr style="background-color: #FA680Ede;color: white;">
                                                    <td>&nbsp;</td>
                                                    <td>योग</td>
                                                    <td>&nbsp;{{get.kul}}</td>


                                                    <td>&nbsp;{{get.dakhil}}</td>
                                                    <td>&nbsp;{{get.sesh}}</td>
                                                    <td>&nbsp;{{get.not_required}}</td>

                                                    <td>&nbsp;{{get.anu_1}}</td>
                                                    <td>&nbsp;{{get.ses_1}}</td>

                                                    <td>&nbsp;{{get.anu_2}}</td>
                                                    <td>&nbsp;{{get.ses_2}}</td>


                                                    <td>&nbsp;{{get.anu_3}}</td>
                                                    <td>&nbsp;{{get.ses_3}}</td>


                                                    <td>&nbsp;{{get.anu_4}}</td>
                                                    <td>&nbsp;{{get.ses_4}}</td>

                                                    <td>&nbsp;{{get.anu_5}}</td>
                                                    <td>&nbsp;{{get.ses_5}}</td>

                                                    <td>&nbsp;{{get.anu_6}}</td>
                                                    <td>&nbsp;{{get.ses_6}}</td>

                                                    <td>&nbsp;{{get.anu_7}}</td>
                                                    <td>&nbsp;{{get.ses_7}}</td>

                                                    <td>&nbsp;{{get.anu_8}}</td>
                                                    <td>&nbsp;{{get.ses_8}}</td>
                                                    <td>&nbsp;{{get.anu_9}}</td>
                                                    <td>&nbsp;{{get.ses_9}}</td>
                                                    <td>&nbsp;{{get.ses_10}}</td>
                                                    <td>&nbsp;{{get.anu_10}}</td>

                                                    <td>&nbsp;{{get.ses_11}}</td>
                                                    <td>&nbsp;{{get.anu_11}}</td>

                                                    <td>&nbsp;{{get.ses_12}}</td>
                                                    <td>&nbsp;{{get.anu_12}}</td>
                                                    <td>&nbsp;{{get.ses_13}}</td>
                                                    <td>&nbsp;{{get.anu_13}}</td>
                                                </tr>
                                            </ng-container>

                                        </ng-container>


                                        <tr style="background-color: #FA680Ede;color: white;">
                                            <td>&nbsp;</td>
                                            <td>महायोग</td>
                                            <td>&nbsp;{{mkul}}</td>


                                            <td>&nbsp;{{mdakhil}}</td>
                                            <td>&nbsp;{{msesh}}</td>
                                            <td>&nbsp;{{mnot_required}}</td>

                                            <td>&nbsp;{{manu_1}}</td>
                                            <td>&nbsp;{{mses_1}}</td>

                                            <td>&nbsp;{{manu_2}}</td>
                                            <td>&nbsp;{{mses_2}}</td>


                                            <td>&nbsp;{{manu_3}}</td>
                                            <td>&nbsp;{{mses_3}}</td>


                                            <td>&nbsp;{{manu_4}}</td>
                                            <td>&nbsp;{{mses_4}}</td>

                                            <td>&nbsp;{{manu_5}}</td>
                                            <td>&nbsp;{{mses_5}}</td>

                                            <td>&nbsp;{{manu_6}}</td>
                                            <td>&nbsp;{{mses_6}}</td>

                                            <td>&nbsp;{{manu_7}}</td>
                                            <td>&nbsp;{{mses_7}}</td>

                                            <td>&nbsp;{{manu_8}}</td>
                                            <td>&nbsp;{{mses_8}}</td>

                                            <td>&nbsp;{{manu_9}}</td>
                                            <td>&nbsp;{{mses_9}}</td>

                                            <td>&nbsp;{{manu_10}}</td>
                                            <td>&nbsp;{{mses_10}}</td>

                                            <td>&nbsp;{{manu_11}}</td>
                                            <td>&nbsp;{{mses_11}}</td>

                                            <td>&nbsp;{{manu_12}}</td>
                                            <td>&nbsp;{{mses_12}}</td>

                                            <td>&nbsp;{{manu_13}}</td>
                                            <td>&nbsp;{{mses_13}}</td>
                                        </tr>

                                        <tr style="font-size: 15px;
                                        font-weight: 500;">
                                            <td></td>
                                            <td></td>
                                            <!-- <td colspan="2">0</td> -->
                                            <td colspan="2">कुल दाखिल </td>
                                            <td colspan="3">{{mdakhil}}</td>
                                            <td colspan="3">कुल शेष</td>
                                            <td colspan="3">{{msesh}}</td>
                                            <td colspan="3">आवश्यकता नही</td>
                                            <td colspan="3">{{mnot_required}}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colspan="6">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>