import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit {
  @ViewChild('UserStatus') UserStatus: any;
  getData: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  id: any;
  saveFormStatus: any;
  isValidFormSubmittedStatus!: boolean;
  constructor(
    private AuthS: AuthService,
    private toast: ToasterService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.onforminitUserStatus();
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.viewdataUser();

  }

  viewdataUser() {


    this.spinner.show();
    this.AuthS.getUser().subscribe((data: any) => {

      this.getData = data.resultData;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.AuthS.deleteUser(id).subscribe(
      (data: any) => {
        this.AuthS.getUser()

        // this.toast.showSuccess(
        //   'Congratulation!, Data has been deleted.'
        // );
        // window.location.href = '/list-user';
        this.router.navigate(['/dashboard/list-user']);
      },

    );
  }

  showModalTask(ids: number) {
    this.id = ids;
  }

  onforminitUserStatus() {
    this.saveFormStatus = this.fb.group({
      status: [''],
    });
  }

  clickFunctionStatusOfUser() {

    this.isValidFormSubmittedStatus = false;
    if (this.saveFormStatus.invalid) {

      this.isValidFormSubmittedStatus = true;
    } else {

      this.AuthS
        .updateUser(this.saveFormStatus.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been Updated.'
          );
          // window.location.href = '/list-user';
          this.router.navigate(['/dashboard/list-user']);
          this.saveFormStatus.reset()
          this.UserStatus.nativeElement.click();
        });

    }


  }



}
