import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CaseService } from 'src/app/services/case.service';
import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.css']
})
export class ViewButtonComponent implements OnInit {
  user: any;
  userID: any;
  roledata: any;
  public value!: string;
  public logo_name!: string;

  constructor(
    private toast: ToasterService,
    private caseService: CaseService
  ) { }

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }

  ngOnInit(): void {
    this.user = localStorage.getItem("userdata");
    this.userID = localStorage.getItem("id");
    this.roledata = JSON.parse(this.user).role;
  }

  confirmAndDelete(id: string) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this case",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel!",
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.isConfirmed) {
        
        this.caseService.deleteCase(id).subscribe({
          next: (data: any) => {
            this.toast.showSuccess(
              'Case deleted successfully'
            );
            window.location.reload();
          },
          error: (error: any) => {
            this.toast.showError(
              'Some error occurred while deleting case'
            );
          }
        })
      }
    }); 
  }
}
