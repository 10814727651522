import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Events } from 'src/app/services/events';

@Component({
  selector: 'app-court-wise-report',
  templateUrl: './court-wise-report.component.html',
  styleUrls: ['./court-wise-report.component.css']
})
export class CourtWiseReportComponent implements OnInit {
  getData: any;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('noteModal') noteModal: any;
  year: any = [];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  getDataCourt: any;
  constructor(
    private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {

    this.CourtData();
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   // pageLength: 10,
    //   ordering: false,
    //   searching: true,
    //   processing: true,
    //   // dom: 'lfrtip', 
    //   dom: 'Blfrtip',
    //   "info": true,
    //   "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
    // };
    this.spinner.show();
    let userID = localStorage.getItem("id");
    // GetCaseData
    this.CaseS.GetCaseDatas(userID).subscribe((data: any) => {
      // this.ngZone.run(() => {
      this.getData = data.resultData;
      
      this.dtOptions = {
        pagingType: 'full_numbers',
        ordering: false,
        searching: true,
        processing: true,
        dom: 'Blfrtip',
        "info": true,
        "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
      };
      let _self = this;
      setTimeout(function () {
        _self.dtTrigger.next();
        _self.spinner.hide();
      }.bind(this));

    });
    // });

    // this.spinner.show();
    // this.events.subscribe('reports', (resp: any) => {


    //   if (resp) {
    //     this.getData = resp;
    //     this.dtTrigger.next();
    //     this.spinner.hide();
    //   }
    // });

  }

  getDataDepartment(d: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (d.target.value != '') {
        dtInstance.column(1)
          .search('^' + d.target.value + '$', true, false, true)
          .draw();
      }
      else {
        window.location.reload()
      }
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn.dataTable.ext.search.pop();
    // this.events.destroy('reports');
  }





  CourtData() {
    this.MasterS.GetCourtdata().subscribe((data: any) => {

      this.getDataCourt = data.resultData;
    });
  }
  viewdata() {
    let userID = localStorage.getItem("id");
    this.CaseS.GetCaseData(userID).subscribe((data: any) => {

      this.getData = data.resultData;
    });
  }


  refresh(): void {
    window.location.reload();
  }


}
