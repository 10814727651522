import { Component } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-formate-currency',
  templateUrl: './formate-currency.component.html',
  styleUrls: ['./formate-currency.component.css']
})
export class FormateCurrencyComponent {
  public value: any;
  public logo_name!: string;

  constructor(private filterCurrency: CurrencyPipe) { }
  agInit(params: ICellRendererParams): void {
    // this.value = params.value;
    this.value = this.filterCurrency.transform(params.value, 'INR');
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}
