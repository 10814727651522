<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <!-- main header -->
        <div class="lter b-b py-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <h3 class="m-n font-medium"> Department Wise Report</h3>
                </div>
                <div class="col-sm-6 text-right poppins hidden-xs">
                    <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
                    <!-- <a href="/add-case" class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" ><i
                  class="fa fa-plus"></i> Add  Case </a> -->
                    <!-- </button> -->
                    <!-- <button data-toggle="modal" data-target="#basicModal" type="submit" class="btn btn-sm text-10 btn-addNew m-r-xs "> Import</button> -->
                </div>
            </div>
        </div>
        <!-- / main header -->
        <div class="row mw">
            <div class="col-md-12">
                <div class="card no-shadow">
                    <div class="tab-content bg-seashell">
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">

                                <!-- <div class="row">

                                    <div class="col-md-4">
                                        <div class="input_grp form-group">
                                            <label for="">Department / विभाग</label>
                                            <select class="form-control select-language form-control-sm validation"
                                                id="department" (change)="getDataDepartment($event)">
                                                <option value=''>Select</option>
                                                <option *ngFor="let data of getDataO" value={{data.org_name}}>
                                                    {{data.org_name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="input_grp form-group">
                                            <label for="">Case Year / वाद वर्ष</label>
                                            <select class="form-control select-language form-control-sm validation"
                                                (change)="getDataYear($event)">
                                                <option value=''>Select</option>
                                                <option *ngFor="let i of year " value={{i}}>{{i}}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="">
                                            <div class="button_group_fotter">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div> -->


                            </div>
                        </div>
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">
                                <app-ag-grid [isExpoartCsv]="true" [userList]="getDataVal" [ids]="'farmer-netural'"
                                    [colDefs]="colDefs" [paginationPageSize]="12"
                                    [remoteGridBinding]="remoteGridBinding"></app-ag-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>