<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="bg-blue lter b-b wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h3 class="m-n font-medium">Case Task List View</h3>
        </div>
        <div class="col-sm-6 text-right poppins hidden-xs">

        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">
                <div class="row" style="margin-top: 1rem; margin-bottom: .5rem;">

                </div>
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th class="text-center">#</th>
                      <th>Case No.</th>
                      <th>Task Type</th>
                      <th>Task Subject</th>
                      <th>Task Name</th>
                      <th>Task Owner</th>
                      <th>Task Due Date</th>
                      <th>Task Status</th>
                      <!-- <th class="text-center">Actions</th> -->
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of getDataTask; let i= index;">

                      <td class="text-center">{{ [i+1] }}</td>
                      <td>{{data?.caseData[0]?.case_no}}</td>
                      <td>{{data?.taskTypeData[0]?.task_type_name}}</td>
                      <td>{{data?.case_task_subject}}</td>
                      <td>{{data?.case_task_name}}</td>
                      <td>{{data?.userData[0]?.username }}</td>
                      <td>{{data?.case_task_duedate}}</td>
                      <!-- <td *ngIf="this.data?.status ==='Complete'"><a class="add-model status_Pending status_Complete" data-id="{{data?._id}}"   >{{data?.status}}</a></td>

                          <td *ngIf="this.data?.status !='Complete'" ><a class="add-model status_Pending" data-id="{{data?._id}}"  >{{data?.status}}</a></td> -->

                      <td *ngIf="this.data?.status ==='Complete'" data-toggle="modal" data-target="#TaskStatus"
                        type="submit">
                        <a class="add-model btn  status_Pending status_Complete" data-id="{{data?._id}}"
                          (click)="showModalTask(data?._id)"> {{data?.status}}</a>
                      </td>
                      <td *ngIf="this.data?.status !='Complete'" data-toggle="modal" data-target="#TaskStatus"
                        type="submit">
                        <a class="add-model btn  status_Pending" data-id="{{data?._id}}"
                          (click)="showModalTask(data?._id)"> {{data?.status}}</a>
                      </td>
                      <td class="text-center">
                        <div class="btn-group dropdown">
                          <button class="btn btn-default w-xxs btn-sm r-2x" data-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-cog g-px-5  text-info text-sm"></i> <span class=""><i
                                class="fa fa-caret-down"></i></span></button>
                          <ul class="dropdown-menu">
                            <li *ngIf="roledata.masterEdit"><a routerLink="/dashboard/case-task-update/{{data?._id}}"
                                routerLinkActive="active"><i class="fa fa-edit"></i> Edit </a></li>
                            <li *ngIf="roledata.masterDelete"><a (click)="deleteUser(data?._id)"><i
                                  class="fa fa-trash-o"></i> Delete </a></li>

                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>






<!-- ..........................................Case task status POPUP MODAL............................ -->

<div class="modal fade" id="TaskStatus" tabindex="-1" role="dialog" aria-labelledby="largeModal2" aria-hidden="true"
  #TaskStatus>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel2">Task Status Update</h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="saveFormTaskStatus" (ngSubmit)="clickFunctionStatusOfTask()">
          <div class="row">
            <div class="col-md-6">
              <div class="input_grp form-group">
                <label for="">Task Status </label>
                <!-- <input class="form-control" type="text" formControlName="status
                  ">   {{this.id}} -->
                <select class="form-control select-language form-control-sm validation" formControlName="status">
                  <option value=''>Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>