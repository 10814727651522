import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RemoteGridApi } from './remote-grid-api';

@Directive({
    selector: '[remoteGridBinding]'
})
export class RemoteGridBindingDirective {
    @Input()
    remoteGridBinding!: RemoteGridApi | any;

    @Output()
    remoteGridReady = new EventEmitter();

    constructor() { }

    @HostListener('gridReady', ['$event']) gridReady(gridApi: GridApi) {
        this.updateGridApi(gridApi);
    }

    updateGridApi(gridApi: any) {
        gridApi.api.setDatasource(this.dataSource);
        this.remoteGridReady.emit(gridApi);
    }

    handleError(err: any) {
        if (this.remoteGridBinding.getDataError) {
            this.remoteGridBinding.getDataError(err);
            return EMPTY;
        } else {
            return
        }

    }

    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            this.remoteGridBinding
                .getData(params)
                .pipe(
                    tap(({ data, totalRecords }) =>
                        params.successCallback(data, totalRecords)
                    ),
                    catchError((err: any): any => {
                        if (this.handleError) {
                            this.handleError(err)
                        }
                    })
                )
                .subscribe();
        }
    };
}
