import { Component, OnInit } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { Events } from 'src/app/services/events';

export interface Beneficiary {
  _id: string;
  case_no: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {

  constructor(private CaseS: CaseService, private events: Events) { }
  ngOnInit(): void {
    // let userID = localStorage.getItem("id");
    // this.CaseS.GetCaseData(userID).subscribe((data: any) => {

    //   // this.events.publish('reports', data.resultData);
    //   // this.getData = data.resultData;
    // });
  }

}
