import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-task-type-update',
  templateUrl: './task-type-update.component.html',
  styleUrls: ['./task-type-update.component.css']
})
export class TaskTypeUpdateComponent implements OnInit {
  isValidFormSubmitted!: boolean;
  saveForm!: FormGroup;
  getData: any;
  id: any;
  EditData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private MasterS: MasterService,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      task_type_name: ['', Validators.required],
      task_type_discription: ['']
    })
    this.MasterS
      .editTaskType(this.id)
      .subscribe((data: any) => {
        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
      })

  }
  onforminit(userData: any) {
    this.saveForm.patchValue(userData);
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.MasterS
        .updateTaskType(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          this.router.navigate(['/dashboard/task-type-list']);
        });
    }
  }

  get f() {
    return this.saveForm.controls;
  }
}
