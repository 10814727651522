import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-infinite-scroll-dropdown',
  templateUrl: './infinite-scroll-dropdown.component.html',
  styleUrls: ['./infinite-scroll-dropdown.component.css']
})
export class InfiniteScrollDropdown2Component implements OnInit {
  @Input() fetchData!: (page: number, pageSize: number) => Observable<any>;
  @Input() multiple: boolean = false;
  @Input() isOpen: boolean = false;
  @Input() valueField: string = 'id'; // Field to be used as the value
  @Input() labelField: string = 'name'; // Field to be used as the label
  @Output() selectionChange = new EventEmitter<any[]>();
  @Output() isOpenChange = new EventEmitter<boolean>();

  items: any[] = [];
  allItems: any[] = [];
  displayedItems: any[] = [];
  selectedItems: any[] = [];
  isDropdownOpen = false;
  majorLoad = true;
  isLoading = false;
  searchQuery = '';
  private chunkSize = 20;
  private currentIndex = 0;
  private searchSubject = new Subject<string>();

  constructor() { }

  ngOnInit() {
    this.loadInitialData();
    this.setupSearchDebounce();
  }

  ngOnChanges() {
    if (this.isOpen !== this.isDropdownOpen) {
      this.isDropdownOpen = this.isOpen;
    }
  }

  loadInitialData() {
    if (!this.fetchData) return;
    this.fetchData(0, Number.MAX_SAFE_INTEGER).subscribe(data => {
      this.allItems = data.resultData || [];
      this.majorLoad = false;
      this.loadMoreItems();
    });
  }

  setupSearchDebounce() {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchQuery: string) => {
      this.searchQuery = searchQuery;
      this.searchItems();
    });
  }

  onSearchInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchSubject.next(input.value);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.isOpenChange.emit(this.isDropdownOpen);
    if (this.isDropdownOpen && this.displayedItems.length === 0) {
      this.loadMoreItems();
    }
  }

  // loadMoreItems() {
  

  //   if (this.isLoading || this.currentIndex >= this.allItems.length) return;
  //   this.isLoading = true;
  //   const newItems = this.allItems.slice(this.currentIndex, this.currentIndex + this.chunkSize);
  //   this.displayedItems = [...this.displayedItems, ...newItems];
  //   this.currentIndex += this.chunkSize;
  //   this.isLoading = false;
  // }

  loadMoreItems() {
    if (this.isLoading || this.currentIndex >= this.allItems.length) return;
    
    if (this.currentIndex >= this.allItems.length) {
        this.isLoading = false;
        return;
    }

    this.isLoading = true;

    const newItems = this.allItems.slice(this.currentIndex, this.currentIndex + this.chunkSize);
    if (newItems.length > 0) {
        this.displayedItems = [...this.displayedItems, ...newItems];
        this.currentIndex += this.chunkSize;
    } else {
        this.isLoading = false;
    }

    this.isLoading = false;
}


  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    const scrollHeight = element.scrollHeight;
    const scrollTop = element.scrollTop;
    const clientHeight = element.clientHeight;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      
      this.loadMoreItems();
    }
  }

  selectItem(item: any) {
    if (this.multiple) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(i => i !== item);
      } else {
        this.selectedItems.push(item);
      }
    } else {
      this.selectedItems = [item];
      this.isDropdownOpen = false;
      this.isOpenChange.emit(this.isDropdownOpen);
    }
    this.searchQuery = '';
    this.searchItems()
    this.selectionChange.emit(this.selectedItems);
  }

  searchItems() {
    if (this.searchQuery.trim() === '') {
      this.displayedItems = this.allItems.slice(0, this.currentIndex);
    } else {
      this.displayedItems = this.allItems.filter(item =>
        item[this.labelField]?.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }

  clearSelection() {
    this.selectedItems = [];
    this.isDropdownOpen = false;
    this.isOpenChange.emit(this.isDropdownOpen);
    this.selectionChange.emit([]);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (this.isDropdownOpen && !target.closest('.dropdown-container')) {
      this.isDropdownOpen = false;
      this.isOpenChange.emit(this.isDropdownOpen);
    }
  }

  public get displayText(): string {
    if (!this.selectedItems.length) {
      return 'Select';
    }

    if (this.multiple) {
      const names = this.selectedItems.map((item) => { return item[this.labelField] });
      const joinedNames = names.join(', ');
      return joinedNames.length <= 40 ? joinedNames : joinedNames.slice(0, 30) + "...";
    } else {
      const itemName = this.selectedItems[0][this.labelField];
      return itemName.length <= 40 ? itemName : itemName.slice(0, 30) + "...";
    }
  }

}
