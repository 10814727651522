import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-ee-update',
  templateUrl: './ee-update.component.html',
  styleUrls: ['./ee-update.component.css']
})
export class EeUpdateComponent implements OnInit {
  getDataDist: any;
  getData: any;
  saveForm!: FormGroup;
  isValidFormSubmitted!: boolean;
  id: any;
  EditData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private ho: MasterService,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      ee_name: ['', Validators.required],
      ee_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')]],
      ee_mobile: ['', [Validators.required, Validators.pattern('^[0-9][0-9]{9}$')]],
      ee_district_id: ['', Validators.required],
      ee_designation: ['', Validators.required],
      ee_village: [''],
      ee_address: [''],
      ee_pincode: ['', [Validators.pattern('^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$')]],
      ee_block: [''],
      ee_divition: [''],
    });

    this.ho
      .editEE(this.id)
      .subscribe((data: any) => {

        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
      });
    this.viewdataOrgSe();
    this.districtData();
  }
  districtData() {
    this.ho.GetDistrictata().subscribe((data: any) => {

      this.getDataDist = data.resultData;
    });
  }

  viewdataOrgSe() {
    this.ho.GetSEdata().subscribe((data: any) => {
      this.getData = data.resultData;
    });
  }

  onforminit(userData: any) {
    // this.saveForm = this.fb.group({
    //   ee_name: [userData.ee_name, Validators.required],
    //   ee_email: [userData.ee_email, [Validators.required, Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'),]],
    //   ee_mobile: [userData.ee_mobile, [Validators.required, Validators.pattern('^[0-9][0-9]{9}$')]],
    //   ee_district_id: [userData.ee_district_id, Validators.required],
    //   ee_designation: [userData.ee_designation, Validators.required],
    //   ee_village: [userData.ee_village],
    //   ee_address: [userData.ee_address],
    //   ee_pincode: [userData.ee_pincode, [Validators.pattern('^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$')]],
    //   ee_block: [userData.ee_block],
    //   ee_divition: [userData.ee_divition],
    // })
    this.saveForm.patchValue(userData);
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.ho
        .updateEE(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          // window.location.href = '/ee-list';
          this.router.navigate(['/dashboard/ee-list']);
          // this.saveForm.reset()
        });
    }
  }



  get f() {
    return this.saveForm?.controls;
  }
}
