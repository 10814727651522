<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="bg-blue lter b-b wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h3 class="m-n font-medium">User List View</h3>
        </div>
        <div class="col-sm-6 text-right poppins hidden-xs">
          <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
          <a routerLink="/dashboard/add-user" routerLinkActive="active"
            class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger"><i class="fa fa-plus"></i>
            Add User </a>
          <!-- </button> -->
        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">
                <div class="row" style="margin-top: 1rem; margin-bottom: .5rem;">

                </div>
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th class="text-center">#</th>
                      <th>User Name</th>
                      <th>Designation</th>
                      <!-- <th>Department</th> -->
                      <!-- <th>Section</th> -->
                      <th>Email</th>
                      <th>Phone</th>
                      <th>District</th>
                      <!-- <th>Executive Engineer Name </th> -->
                      <th>Organization Name</th>
                      <th>Office Name</th>
                      <th>Circle Name</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" *ngFor="let data of getData; let i= index;">
                      <td class="text-center">{{ [i+1] }}</td>
                      <td>{{data?.username}}</td>
                      <td>{{data?.designation}}</td>
                      <!-- <td>{{data?.deptData[0]?.org_name}}</td> -->
                      <!-- <td>{{data?.sectionData[0]?.section_name}}</td> -->
                      <td>{{data?.email}}</td>
                      <td>{{data?.mobile}}</td>
                      <!-- <td>{{data?.districtData[0]?.district}}</td> -->
                      <td>
                        <ul>
                          <!-- <li *ngFor="let index of data?.districtData"
                                style="margin: 0 0 -2px;">
                                {{ index.district }}
                          </li> -->
                          <li *ngFor="let message of data?.districtData.slice(0,5)" style="margin: 0 0 -2px;">
                            {{ message.district }}
                          </li>

                        </ul>
                      </td>
                      <!-- <td>{{data?.eeData[0]?.ee_name}} </td> -->
                      <td>{{data?.orgData?.length>0?data?.orgData[0]?.sub_org_name:''}} </td>
                      <td>{{data?.office_name}}</td>
                      <td>{{data?. circle_name}}</td>
                      <td>{{data?.created_by}}</td>
                      <td>{{data?.updated_by}}</td>
                      <td>{{data?.roleData[0]?.role_name}}</td>
                      <td *ngIf="data?.status ===true" data-toggle="modal" data-target="#UserStatus" type="submit">
                        <a class="add-model btn status_Pending status_Complete" data-id="{{data?._id}}"
                          (click)="showModalTask(data?._id)"> Active</a>
                      </td>
                      <td *ngIf="data?.status !=true" data-toggle="modal" data-target="#UserStatus" type="submit">
                        <a class="add-model btn status_Pending" data-id="{{data?._id}}"
                          (click)="showModalTask(data?._id)"> Inactive</a>
                      </td>
                      <td class="text-center">
                        <div class="btn-group dropdown">
                          <button class="btn btn-default w-xxs btn-sm r-2x" data-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-cog g-px-5  text-info text-sm"></i> <span class=""><i
                                class="fa fa-caret-down"></i></span></button>
                          <ul class="dropdown-menu">
                            <li><a routerLink="/dashboard/update-user/{{data._id}}" routerLinkActive="active"><i
                                  class="fa fa-edit"></i> Edit </a>
                            </li>
                            <!-- <li><a (click)="deleteUser(data._id)"><i class="fa fa-trash-o"></i> Delete </a></li> -->

                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>





<!-- ..........................................User status POPUP MODAL............................ -->

<div class="modal fade" id="UserStatus" tabindex="-1" role="dialog" aria-labelledby="largeModal2" aria-hidden="true"
  #UserStatus>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel2">User Status </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="saveFormStatus" (ngSubmit)="clickFunctionStatusOfUser()">
          <div class="row">
            <div class="col-md-6">
              <div class="input_grp form-group">
                <label for="">User Status </label>
                <!-- <input class="form-control" type="text" formControlName="status
                  ">   {{this.id}} -->
                <select class="form-control select-language form-control-sm validation" formControlName="status">
                  <option value=''>Select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>



  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;
    ">Loading...</p>
  </ngx-spinner>