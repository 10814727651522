import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-petitioner-list',
  templateUrl: './petitioner-list.component.html',
  styleUrls: ['./petitioner-list.component.css']
})
export class PetitionerListComponent implements OnInit {
  getDataPet: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  roledata: any;
  user: any;
  districts: any;
  constructor(
    private router: Router,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService
  ) { }


  ngOnInit(): void {
    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.petitionerData();
  }
  petitionerData() {
    this.spinner.show();
    this.MasterS.GetPetitionerdata().subscribe((data: any) => {

      this.getDataPet = data.resultData;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.MasterS.deletePetitioner(id).subscribe(
      (data: any) => {
        this.MasterS.GetPetitionerdata()

        // window.location.href = '/petitioner-list';
        this.router.navigate(['/dashboard/petitioner-list']);
      },

    );
  }
}
