import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import {
  CellValueChangedEvent,
  GridReadyEvent,
  SelectionChangedEvent,
  DomLayoutType,
  GridOptions,
  ColumnApi,
  GridApi
} from 'ag-grid-community';
import Swal from 'sweetalert2';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, of } from 'rxjs';
import { RemoteGridApi } from './remote-grid-api';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.css']
})
export class AgGridComponent implements OnInit {

  @Input() colDefs: any[] = [];
  @Input() userList: any[] = [];
  @Input() ids: any = 'filter-text-box';
  @Input() isExpoartCsv: boolean = false;
  @Input() isSearch: boolean = false;
  @Input() paginationPageSize: number = 12;
  @Input() isThemeChange: boolean = false;
  @Output() dataSource: any = new EventEmitter();
  @Input() remoteGridBinding!: RemoteGridApi | any;
  @Output() globalSearchTerm: any = new EventEmitter();
  public cacheBlockSize = -1;
  @Input() searchValue: any = "";
  maxConcurrentDataSourceRequests: number = 10;
  public maxBlocksInCache = 2;
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  public infiniteInitialRowCount = 1000;
  filterIcons = {
    filter: '<img src="assets/icons8-filter-24.png" style="width: 14px; height: 14px;">'
  }
  private columnApi!: ColumnApi;
  defaultColDef: any = {
    filter: true,
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
  };
  selectedDataToAG: any[] = [];
  public domLayout: DomLayoutType = 'autoHeight';
  public autoGroupColumnDef: any = {
    minWidth: 200,
  };
  public groupDisplayType: any = 'multipleColumns';
  public groupDefaultExpanded = 1;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  themeClass = 'ag-theme-balham';
  private gridApi!: GridApi;

  constructor() { }

  ngOnInit(): void {
    this.gridOptions.api?.showLoadingOverlay();
  }

  applyFilters(filter: any) {
    if (this.gridApi) {
      this.gridApi.setFilterModel(filter.filterModel);
      this.gridApi.onFilterChanged();
    }
  }

  onSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    const searchValue = target.value.trim();

    of(searchValue).pipe(
      debounceTime(10000),
      distinctUntilChanged(),
      switchMap((value: string) => {
        return this.applyFilter(value);
      })
    ).subscribe();
  }

  applyFilter(searchValue: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.gridApi.setFilterModel({
        global: { value: searchValue }
      });
      this.gridApi.onFilterChanged();
      observer.next();
      observer.complete();
    });
  }

  doChangeTheme(e: any) {
    this.themeClass = e.target.value;
  }

  onSelectionChanged = (event: SelectionChangedEvent) => {
    this.selectedDataToAG = this.gridApi.getSelectedRows();
  };

  exportToCsv() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to export the data as a CSV file!",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, download it!",
      cancelButtonText: "Cancel!",
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.gridApi.exportDataAsCsv();
      }
    });

  }


  exportToPdf() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to export the data as a PDF file!",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, download it!",
      cancelButtonText: "Cancel!",
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [800, 600]
        });

        let data: any[] = [];
        let headers: any[] = [];
        this.userList.forEach((item: any) => {
          data.push(Object.values(item));
          headers.push(Object.keys(item).map((item: any) => { return item.charAt(0).toUpperCase() + item.slice(1).split(/(?=[A-Z])/).toString().replace(/,/g, ' ') }))
        })

        autoTable(doc, {
          headStyles: {
            fillColor: [136, 104, 56],
            fontStyle: 'bold', // Optionally make the text bold
            halign: 'center',
            valign: 'middle'  // Center align the header content
          },
          theme: 'grid',
          head: [headers[0]],
          body: data.slice(1),
          columnStyles: {
            Project: { cellWidth: 'wrap' },
          },
          tableWidth: 'auto',
          startY: 20,
        });
        doc.save('grid.pdf');
      }
    });


  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  gridOptions: GridOptions | any = {
    rowDragManaged: true,
    onRowDragEnd: this.onRowDragEnd.bind(this),
    rowModelType: 'infinite',
    enableServerSideFilter: false,
    enableServerSideSorting: false,
    suppressServerSideFullWidthLoadingRow: true,
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading..jgjkdgaskgkgdfkgsdagkdgkgsdkjsagjkgkeg.</span>',
    overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No data available</span>',
  };
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';


  onRowDragEnd(event: any) {
    const draggedRowData = event.node.data; // Data of the dragged row
    const dropRowIndex = event.overIndex; // Index where the row was dropped
    const dropRowNode = this.gridApi.getDisplayedRowAtIndex(dropRowIndex);
    const droppedRowData = dropRowNode ? dropRowNode.data : null; // Data of the dropped row
  }
}
