import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
// RxJS v6+
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Events {

    private channels: { [key: string]: ReplaySubject<any>; } = {};

    subscribe(topic: string, observer: (_: any) => void): Subscription {
        if (!this.channels[topic]) {
            this.channels[topic] = new ReplaySubject<any>();
        }

        return this.channels[topic].subscribe(observer);
    }


    publish(topic: string, data: any): void {
        const subject = this.channels[topic];
        if (!subject) {
            return;
        }

        subject.next(data);
    }

    destroy(topic: string): null | any {
        const subject = this.channels[topic];
        if (!subject) {
            return;
        }

        subject.complete();
        // subject.unsubscribe();
        // delete this.channels[topic];
    }
}