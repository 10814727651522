import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-permission-add',
  templateUrl: './role-permission-add.component.html',
  styleUrls: ['./role-permission-add.component.css']
})
export class RolePermissionAddComponent implements OnInit {
  saveForm: any;
  isValidFormSubmitted!: boolean;

  constructor(
    private RoleS: RolePermissionService,
    private toast: ToasterService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.onforminit();
  }



  onforminit() {
    this.saveForm = this.fb.group({
      role_name: ['', Validators.required],
      description: [''],
      caseCreate: false,
      caseView: false,
      caseEdit: false,
      caseDelete: false,
      caseDetailsCreate: false,
      caseDetailsView: false,
      caseDetailsEdit: false,
      caseTimeLineView: false,
      caseTaskCreate: false,
      caseTaskView: false,
      caseTaskEdit: false,
      caseTaskDelete: false,
      caseDocumentCreate: false,
      caseDocumentView: false,
      caseDocumentEdit: false,
      caseDocumentDelete: false,
      reportView: false,
      petitionerCreate: false,
      petitionerView: false,
      petitionerEdit: false,
      petitionerDelete: false,
      respondentCreate: false,
      respondentView: false,
      respondentEdit: false,
      respondentDelete: false,
      counselCreate: false,
      counselView: false,
      counselEdit: false,
      counselDelete: false,
      userCreate: false,
      userView: false,
      userEdit: false,
      userDelete: false,
      roleCreate: false,
      roleView: false,
      roleEdit: false,
      roleDelete: false,
      masterCreate: false,
      masterView: false,
      masterEdit: false,
      masterDelete: false,
      resourceCreate: false,
      resourceView: false,
      resourceEdit: false,
      resourceDelete: false,
    });
  }
  clickFunction() {


    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.toast.showError(
        'Required fields are missing.'
      );
      this.isValidFormSubmitted = true;
    } else {

      this.RoleS
        .AddRoles(this.saveForm.value)
        .subscribe((data: any) => {

          // this.toast.showSuccess(
          //   'Congratulation!, Data has been submited.'
          // );
          // window.location.href = '/role-permission-list';
          if (data.statuscode === 200) {

            this.toast.showSuccess(
              'Congratulation!, Data has been submited.'
            );
            // window.location.href = '/role-permission-list';
            this.router.navigate(['/dashboard/role-permission-list']);
          }
          else {
            this.toast.showError('Oops! This Role is all ready exist!');
          }
        });
    }
  }

  get f() {
    return this.saveForm.controls;
  }

}
