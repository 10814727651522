import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-role-permission-update',
  templateUrl: './role-permission-update.component.html',
  styleUrls: ['./role-permission-update.component.css']
})
export class RolePermissionUpdateComponent implements OnInit {
  id: any;
  EditData: any;
  isValidFormSubmitted!: boolean;
  saveForm: any;
  isDisabled!: boolean;

  constructor(
    private RoleS: RolePermissionService,
    private toast: ToasterService,
    private fb: UntypedFormBuilder,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');



    this.RoleS
      .EditRoles(this.id)
      .subscribe((data: any) => {

        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
      })
  }
  get f() {
    return this.saveForm.controls;
  }

  onforminit(user: any) {
    this.saveForm = this.fb.group({
      role_name: [user?.role_name, Validators.required],
      description: [user?.description],
      administration: [user?.administration],
      caseCreate: [user?.caseCreate],
      caseView: [user?.caseView],
      caseEdit: [user?.caseEdit],
      caseDelete: [user?.caseDelete],
      caseDetailsCreate: [user?.caseDetailsCreate],
      caseDetailsView: [user?.caseDetailsView],
      caseDetailsEdit: [user?.caseDetailsEdit],
      caseTimeLineView: [user?.caseTimeLineView],
      caseTaskCreate: [user?.caseTaskCreate],
      caseTaskView: [user?.caseTaskView],
      caseTaskEdit: [user?.caseTaskEdit],
      caseTaskDelete: [user?.caseTaskDelete],
      caseDocumentCreate: [user?.caseDocumentCreate],
      caseDocumentView: [user?.caseDocumentView],
      caseDocumentEdit: [user?.caseDocumentEdit],
      caseDocumentDelete: [user?.caseDocumentDelete],
      reportView: [user?.reportView],
      petitionerCreate: [user?.petitionerCreate],
      petitionerView: [user?.petitionerView],
      petitionerEdit: [user?.petitionerEdit],
      petitionerDelete: [user?.petitionerDelete],
      respondentCreate: [user?.respondentCreate],
      respondentView: [user?.respondentView],
      respondentEdit: [user?.respondentEdit],
      respondentDelete: [user?.respondentDelete],
      counselCreate: [user?.counselCreate],
      counselView: [user?.counselView],
      counselEdit: [user?.counselEdit],
      counselDelete: [user?.counselDelete],
      userCreate: [user?.userCreate],
      userView: [user?.userView],
      userEdit: [user?.userEdit],
      userDelete: [user?.userDelete],
      roleCreate: [user?.roleCreate],
      roleView: [user?.roleView],
      roleEdit: [user?.roleEdit],
      roleDelete: [user?.roleDelete],
      masterCreate: [user?.masterCreate],
      masterView: [user?.masterView],
      masterEdit: [user?.masterEdit],
      masterDelete: [user?.masterDelete],
      resourceCreate: [user?.resourceCreate],
      resourceView: [user?.resourceView],
      resourceEdit: [user?.resourceEdit],
      resourceDelete: [user?.resourceDelete],
    });
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.RoleS
        .updateRoles(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          this.router.navigate(['/dashboard/role-permission-list']);
        });
    }
  }

}
