
import { ChangeDetectorRef, Component, ElementRef, Injectable, NgZone, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { CaseService } from 'src/app/services/case.service';
import { Form, UntypedFormBuilder, UntypedFormControl, NgForm, Validators, FormControl } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
declare const window: any;

@Component({
  selector: 'app-add-case',
  templateUrl: './add-case.component.html',
  styleUrls: ['./add-case.component.css']
})
@Injectable()
export class AddCaseComponent implements OnInit {
  public websiteMultiCtrl: FormControl = new FormControl();
  reason_filing = true
  writ_statusCondition: boolean = true
  pet_typeCondition: boolean = true
  res_typeCondition: boolean = true
  myControl = new UntypedFormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]> | undefined;
  isContempt: boolean = false;
  refrenceUk: boolean = false;
  @ViewChild('noteModal') noteModal: any;
  @ViewChild('counsel') counsel: any;
  @ViewChild('respondent') respondent: any;
  @ViewChild('petitioner') petitioner: any;
  @ViewChild('representing') representing: any;
  @ViewChild('searchinp') searchinp!: ElementRef;
  getData: any = [];
  getDataC: any = [];
  getDataD: any = [];
  getDataS: any = [];
  getDataDist: any = [];
  getDataH: any = [];
  getDataO: any = [];
  getDataCE: any = [];
  getDataSE: any = [];
  getDataEE: any = [];
  getDataCl: any = [];
  getDataCS: any = [];
  getDataT: any = [];
  petitionerdata: any = []
  saveFormPetitioner: any;
  isValidFormSubmitted!: boolean;
  saveFormRespondent: any;
  isValidFormSubmittedRes!: boolean;
  isValidFormSubmittedRepres!: boolean;
  saveFormRepresenting: any;
  saveFormCounsel: any;
  isValidFormSubmittedCoun!: boolean;
  saveFormContempt: any;
  isValidFormSubmittedCont!: boolean;
  dropDowns: any = {};
  caseSearch: any = {};
  unitDataByHo: any;
  getDataPet: any;
  getDataRes: any;
  petiById: any = [];
  resById: any;
  getDataContempt: any;
  saveFormMain: any;
  isValidFormSubmittedMain!: boolean;
  getDataRepresenting: any;
  getDataCounsel: any;
  represById: any;
  councelById: any;
  subOrgDataByOrg: any;
  date: Date | undefined;
  year: any = [];
  public qrCodeVal: string = '';
  public width: number = 50;
  closeModal: any;
  largeModal00: any;
  d: any;
  getDatacaseSub: any;
  caseSubs: any = [];
  seDataBySubOrg: any;
  EeDataBySe: any;
  getEEAllData: any;
  EeDataById: any;
  getCaseNo: any;
  refcasedata: any;
  public websiteMultiFilterCtrl: FormControl = new FormControl();
  logindata: any;
  username: any;
  _id: any;
  roledata: any;
  districts: any;
  selectedCityIds: string[] = [];
  data: any = {};
  filteredData: any = [];
  buffer: any = {};
  subOrgMeta: any;
  isRendering: boolean = false;
  currentPage = 1;
  itemsPerPage = 51;
  totalPages = 0;
  paginatedData: any[] = [];
  searchQuery: string = '';
  showDotsAfter: boolean = false;
  displayPages: any = [];
  showDotsBefore: boolean = false;
  pages: number[] = [];
  caseid: any;
  operation: string | null = '';
  today: string = '';
  uploadProgress: number = 0;
  errorMessages: any = [];

  public noWhitespaceValidator(control: FormControl) {
    if (typeof control.value == 'string' && control.value.length) {
      return (control.value || '').trim().length ? null : { 'whitespace': true };
    } else {
      return false
    }
  }

  constructor(
    private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private CaseS: CaseService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }


  isContempts(status: boolean) {
    this.isContempt = status;
  }

  ngOnInit(): void {
    this.username = localStorage.getItem('id') ? localStorage.getItem('id') : this.router.navigate(['/']);
    this.logindata = JSON.parse(localStorage.getItem('userdata') || '{}');
    this.roledata = this.logindata.role;
    this.districts = this.logindata.district
    const dateToday = new Date();
    this.today = dateToday.toISOString().split('T')[0];
    this.year = Array.from({ length: 51 }, (_, i) => dateToday.getFullYear() - i);
    this.onforminitPet();
    this.onforminitRes();
    this.onforminitRepres();
    this.onforminitCoun();
    this.onforminitCont();
    this.onforminitMain();

    this.initializeData().subscribe({
      next: () => {
        this.route.paramMap.subscribe(params => {
          this.caseid = params.get('id');
          this.operation = params.get('operation');
          if (this.caseid) {
            this.fetchCaseData(this.caseid);
          }
          if (this.operation == 'view') {
            this.saveFormMain.disable();
          }
        });
      },
      error: (err) => {
        console.error('Error initializing data', err);
      }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

    // this.route.paramMap.subscribe(params => {
    //   this.caseid = params.get('id');
    //   this.CaseS.GetCaseSingle(this.caseid).subscribe((data: any) => {
    //     this.saveFormMain.patchValue(data.resultData)
    //     this.saveFormMain.get('case_no')?.disable();

    //     event.target.value = data.resultData.case_orgnaization_id
    //     this.getSubOrgByOrgd(event)
    //     event.target.value = data.resultData.case_sun_org_id
    //     this.getSeBySubOrgD(event)
    //     event.target.value = data.resultData.case_se_id
    //     this.getEeBySeD(event)
    //     event.target.value = data.resultData.case_ee_id
    //     this.getEeByIdData(event)
    //   });
    // });
  }

  private initializeData(): Observable<void> {
    this.spinner.show();

    return forkJoin({
      caseNo: this.MasterS.GetCaseNos(),
      caseSubject: this.MasterS.GetCaseSubject(),
      deptData: this.MasterS.GetDeptdata(),
      courtData: this.MasterS.GetCourtdata(),
      secData: this.MasterS.GetSecdata(),
      districtData: this.MasterS.GetDistrictata(),
      hoData: this.MasterS.GetHOdata(),
      orgData: this.MasterS.GetOrgdata(),
      ceData: this.MasterS.GetCEdata(),
      seData: this.MasterS.GetSEdata(),
      eeData: this.MasterS.GetEEdata(),
      typeData: this.MasterS.GetTypedata(),
      statusData: this.MasterS.GetStatusdata(),
      classiData: this.MasterS.GetClassidata(),
      contemptData: this.MasterS.GetContemptdata(),
      petitionerData: this.MasterS.GetPetitionernames(),
      respondentData: this.MasterS.getRespondentNames(),
      representingData: this.MasterS.GetRepresentingdata(),
      counselData: this.MasterS.GetCounseldata(),
      subOrgMeta: this.MasterS.getSubOrgMeta()
    }).pipe(
      map(results => {
        this.getCaseNo = (results.caseNo as any)?.resultData || [];
        this.getDatacaseSub = (results.caseSubject as any)?.resultData || [];
        this.getDataD = (results.deptData as any)?.resultData || [];
        this.getDataC = (results.courtData as any)?.resultData || [];
        this.getDataS = (results.secData as any)?.resultData || [];
        this.getDataDist = (results.districtData as any)?.resultData || [];
        this.getDataH = (results.hoData as any)?.resultData || [];
        this.getDataO = (results.orgData as any)?.resultData || [];
        this.getDataCE = (results.ceData as any)?.resultData || [];
        this.getDataSE = (results.seData as any)?.resultData || [];
        this.getDataEE = (results.eeData as any)?.resultData || [];
        this.getDataT = (results.typeData as any)?.resultData || [];
        this.getDataCS = (results.statusData as any)?.resultData || [];
        this.getDataCl = (results.classiData as any)?.resultData || [];
        this.getDataContempt = (results.contemptData as any)?.resultData || [];
        this.getDataPet = (results.petitionerData as any)?.resultData || [];
        this.getDataRes = (results.respondentData as any)?.resultData || [];
        this.getDataRepresenting = (results.representingData as any)?.resultData || [];
        this.getDataCounsel = (results.counselData as any)?.resultData || [];
        this.subOrgMeta = (results.subOrgMeta as any)?.resultData || [];
      }),
      finalize(() => {
        this.getCaseSubjects({ target: { value: 'भूमि' } });
        this.cdr.detectChanges();
        this.spinner.hide();
      })
    );
  }

  private fetchCaseData(caseId: string): void {
    this.CaseS.GetCaseSingle(caseId).subscribe({
      next: (data: any) => {
        if (data) {
          this.updateFormFields(data.resultData);
        }
      },
      error: (error) => {
        console.error('Error fetching case details', error);
      }
    });
  }

  fetchCaseNos() {
    return this.MasterS.GetCaseNos();
  }

  onSelectionChange(selectedItems: any[], type: string) {
    if (selectedItems.length) {
      this.caseSearch[type] = selectedItems[0]._id;
      this.saveFormMain.get(type).setValue(selectedItems[0].case_no); 
      let event: any = {
        target: {
          value: ''
        }
      }
      event.target.value = selectedItems[0].case_no;
      this.getCaseNoByRefNo(event)
    }
    else {
      delete this.caseSearch[type]
      this.refcasedata = []
    }
  }

  closeAll(isOpen: boolean, openKey: string) {
    this.dropDowns = {}
    this.dropDowns[openKey] = isOpen;
  }

  private updateFormFields(data: any) {
    let event: any = {
      target: {
        value: ''
      }
    }
    event.target.value = data.case_orgnaization_id;
    this.getSubOrgByOrgd(event);
    event.target.value = data.case_sun_org_id;
    this.getSeBySubOrgD(event);
    event.target.value = data.case_se_id;
    this.getEeBySeD(event);
    event.target.value = data.case_ee_id;
    this.getEeByIdData(event);
    event.target.value = data.ref_case_no;
    this.getCaseNoByRefNo(event)
    if (data.case_contempt == 'Yes') {
      this.isContempts(true)
    }
    event.target.value = data.case_subjectType;
    this.getCaseSubjects(event)
    this.saveFormMain.patchValue(data);
    this.saveFormMain.get('case_no')?.disable();
    this.getCouncelById();
    this.getPetiById()
    this.getResById()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.getDataPet.filter((option: any) => option.name.toLowerCase().includes(filterValue))
    return this.getDataPet.filter((option: any) => {
      if (filterValue === null || filterValue === undefined || filterValue === '') {
        return true
      }
      else if (option.name.toLowerCase().includes(filterValue)) {
        return true
      }
      else {
        return false
      }
    });
  }

  getCaseNoByRefNo(e: any) {
    this.MasterS
      .GetCaseNoByRefNo({
        ref_case_no: e.target.value,
      })
      .subscribe((data: any) => {
        this.refcasedata = data.resultData;
      });
  }

  getUnitByhoffice(e: any) {
    this.MasterS
      .getUnitByHo({
        head_office_id: e.target.value,
      })
      .subscribe((data: any) => {
        this.unitDataByHo = data.result;
      });
  }

  getSubOrgByOrgd(e: any) {
    let org = e.target.value
    this.subOrgDataByOrg = this.subOrgMeta?.filter((subOrg: any) => subOrg?.org_id?.includes(org))
    this.saveFormMain.controls.case_se_id.reset();
    this.saveFormMain.controls.case_ee_id.reset();
  }

  getCaseSubjects(e: any) {
    let type = e.target.value
    this.caseSubs = this.getDatacaseSub?.filter((subj: any) => subj?.case_subjectType?.includes(type))
    this.saveFormMain.controls.case_subject.reset();
  }

  getSeBySubOrgD(e: any) {
    let org = e.target.value
    this.seDataBySubOrg = this.getDataSE.filter((se: any) => se?.sub_org_id?.includes(org))
    this.saveFormMain.controls.case_ee_id.reset();
  }

  getEeBySeD(e: any) {
    let org = e.target.value
    this.EeDataBySe = this.getDataEE.filter((ee: any) => ee?.se_id?.includes(org))
  }

  getEeByIdData(e: any) {
    let org = e.target.value
    this.EeDataById = this.getDataEE.filter((ee: any) => ee?._id?.includes(org))
  }

  getPetiById() {
    this.petiById = []
    let petitionerData = this.saveFormMain.value.case_petitioner_id;
    for (let i of petitionerData) {
      this.MasterS
        .getPetiByIdData({
          case_petitioner_id: i,
        })
        .subscribe((data: any) => {
          this.petiById.push(data.resultData[0]);
        });
    }
  }

  getResById() {
    this.resById = []
    let respondentData = this.saveFormMain.value.case_respondent_id
    for (let i of respondentData) {
      this.MasterS
        .getResByIdData({
          case_respondent_id: i,
        })
        .subscribe((data: any) => {
          this.resById.push(data.resultData[0]);

        });
    }
  }

  getRepresById(e: any) {
    this.MasterS
      .getResByIdData({
        case_respondent_id: e.target.value,
      })
      .subscribe((data: any) => {
        this.represById = data.resultData;
      });
  }

  getCouncelById() {
    this.councelById = []
    let councelData = this.saveFormMain.value.case_counsel_id
    for (let i of councelData) {
      this.MasterS
        .getCouncelByIdData({
          case_counsel_id: i,
        })
        .subscribe((data: any) => {
          this.councelById.push(data.resultData[0]);
        });
    }
  }

  onforminitPet() {
    this.saveFormPetitioner = this.fb.group({
      pet_employee: ['Employee'],
      pet_name: ['', this.noWhitespaceValidator],
      pet_designation: ['', this.noWhitespaceValidator],
      // pet_email: [''],
      // pet_mobile: [''],
      pet_district_id: ['', this.noWhitespaceValidator],
      // pet_block: [''],
      // pet_village: [''],
      // pet_pincode: ['', [Validators.pattern('^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$')]],
      // pet_address: [''],
      // pet_divition: [''],
    });
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    this.saveFormPetitioner.markAllAsTouched();
    if (this.saveFormPetitioner.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      let pet = this.saveFormPetitioner.value
      if (!pet.pet_name?.trim() && pet.pet_employee == 'Non Employee') {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill name of the petitioner.'
        );
        return;
      }
      if (!pet.pet_designation?.trim() && pet.pet_employee == 'Employee') {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill designation of the petitioner.'
        );
        return;
      }
      this.MasterS
        .savePetitioner(pet)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.saveFormMain.controls['case_petitioner_id'].value.push(data.resultData._id)
          this.saveFormMain.get('case_petitioner_id').setValue(this.saveFormMain.controls['case_petitioner_id'].value);
          this.getPetiById();
          this.saveFormPetitioner.reset()
          this.saveFormPetitioner.get('pet_employee').setValue('Employee');
          this.petitioner.nativeElement.click();
        });
      this.MasterS.GetPetitionerdata().subscribe((data: any) => {
        this.getDataPet = data.resultData;
      });
    }
  }

  get f() {
    return this.saveFormPetitioner.controls;
  }

  onforminitRes() {
    this.saveFormRespondent = this.fb.group({
      res_employee: ['Employee'],
      res_name: [''],
      res_designation: [''],
      res_email: ['', Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')],
      res_mobile: ['', Validators.pattern('^[0-9][0-9]{9}$')],
      res_district_id: [''],
      res_block: ['', this.noWhitespaceValidator],
      res_village: [''],
      res_pincode: ['', Validators.pattern('^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$')],
      res_address: ['', this.noWhitespaceValidator],
      res_divition: ['', this.noWhitespaceValidator],
    });
  }

  clickFunctionRes() {
    this.isValidFormSubmittedRes = false;
    this.saveFormRespondent.markAllAsTouched();
    if (this.saveFormRespondent.invalid) {
      this.isValidFormSubmittedRes = true;
    } else {
      let resp = this.saveFormRespondent.value;
      if (resp.res_employee == 'Employee' && !resp.res_designation?.trim()) {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill designation of the respondent.'
        );
        return;
      }
      if (resp.res_employee == 'Non Employee' && !resp.res_name?.trim()) {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill name of the respondent.'
        );
        return;
      }
      this.MasterS
        .saveRespondent(resp)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.saveFormMain.controls['case_respondent_id'].value.push(data.resultData._id)
          this.saveFormMain.get('case_respondent_id').setValue(this.saveFormMain.controls['case_respondent_id'].value);
          this.getResById();
          this.saveFormRespondent.reset();
          this.saveFormRespondent.get('res_employee').setValue('Employee');
          this.respondent.nativeElement.click();
        });
      this.MasterS.GetRespondentdata().subscribe((data: any) => {
        this.getDataRes = data.resultData;
      });
    }
  }

  onScroll(event: WheelEvent) {
    const input = event.target as HTMLInputElement;
    input.blur(); // Remove focus from the input when scrolling
  }

  validateNumberInput(event: any) {
    const input = event.target as HTMLInputElement;
    if (parseInt(input.value) < 0) {
      input.value = ''
    }
  }

  numAndSpChars(event: KeyboardEvent): void {
    const allowedPattern = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Escape',
      'Control',
      'Meta',
      'Shift',
      'Delete',
      'Home',
      'End',
      'PageUp',
      'PageDown'
    ];

    if (
      allowedKeys.includes(event.key) ||
      (event.ctrlKey || event.metaKey) &&
      (event.key === 'a' || event.key === 'c' || event.key === 'v' || event.key === 'x')
    ) {
      return;
    }

    if (!allowedPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  get res() {
    return this.saveFormRespondent.controls;
  }

  onforminitRepres() {
    this.saveFormRepresenting = this.fb.group({
      repres_name: ['', Validators.required],
      repres_designation: ['', Validators.required],
      repres_email: ['', Validators.required],
      repres_mobile: ['', Validators.required],
      repres_district_id: ['', Validators.required],
      repres_block: [''],
      repres_village: [''],
      repres_pincode: [''],
      repres_address: [''],
    });
  }

  clickFunctionRepres() {
    this.isValidFormSubmittedRepres = false;
    if (this.saveFormRepresenting.invalid) {
      this.isValidFormSubmittedRepres = true;
    } else {
      this.MasterS
        .saveRepresenting(this.saveFormRepresenting.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.saveFormRepresenting.reset();
          this.representing.nativeElement.click();
        });

      this.MasterS.GetRepresentingdata().subscribe((data: any) => {
        this.getDataRepresenting = data.resultData;
      });
    }
  }

  get repres() {
    return this.saveFormRepresenting.controls;
  }

  onforminitCoun() {
    this.saveFormCounsel = this.fb.group({
      coun_name: ['', Validators.required],
      coun_email: ['', Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')],
      coun_mobile: ['', Validators.pattern('^[0-9][0-9]{9}$')],
    });
  }

  clickFunctionCounsel() {
    this.isValidFormSubmittedCoun = false;
    if (this.saveFormCounsel.invalid) {
      this.isValidFormSubmittedCoun = true;
    } else {
      this.MasterS
        .saveCounsel(this.saveFormCounsel.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.saveFormMain.controls['case_counsel_id'].value.push(data.resultData._id)
          this.saveFormMain.get('case_counsel_id').setValue(this.saveFormMain.controls['case_counsel_id'].value);
          this.getCouncelById();
          this.saveFormCounsel.reset();
          this.counsel.nativeElement.click();
        });

      this.MasterS.GetCounseldata().subscribe((data: any) => {
        this.getDataCounsel = data.resultData;
      });
    }
  }

  get coun() {
    return this.saveFormCounsel.controls;
  }

  onforminitCont() {
    this.saveFormContempt = this.fb.group({
      contempt_no: ['', Validators.required],
      contempt_year: [''],
      contempt_reason: [''],
      contempt_status: [''],
      contempt_remark: ['']
    });
  }

  clickFunctionContempt() {
    this.isValidFormSubmittedCont = false;
    this.saveFormContempt.markAllAsTouched();
    if (this.saveFormContempt.invalid) {
      this.isValidFormSubmittedCont = true;
    } else {
      this.MasterS
        .saveContempt(this.saveFormContempt.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.saveFormContempt.reset();
          this.noteModal.nativeElement.click();
        });

      this.MasterS.GetContemptdata().subscribe((data: any) => {
        this.getDataContempt = data.resultData;
      });
    }
  }

  get cont() {
    return this.saveFormContempt.controls;
  }


  onforminitMain() {
    this.saveFormMain = this.fb.group({
      case_contempt: ["No"],
      case_contempt_id: [""],
      reason_non_compliance: [""],
      case_slp: ["No"],
      hod: ["No"],
      PIL: ["No"],
      PSR: ["No"],
      CSR: ["No"],
      case_court_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_section_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_district_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_ho_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_orgnaization_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_se_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_ee_id: ["", [Validators.required, this.noWhitespaceValidator]],
      writ_status: ["Accepted", [Validators.required, this.noWhitespaceValidator]],
      case_no: ["", [Validators.required, this.noWhitespaceValidator]],
      case_year: ["", [Validators.required, this.noWhitespaceValidator]],
      case_petitioner_id: [[], [Validators.required, this.noWhitespaceValidator]],
      case_respondent_id: [[], [Validators.required, this.noWhitespaceValidator]],
      case_notice_no: [""],
      case_filing_date: [""],
      case_subjectType: ["भूमि", [Validators.required, this.noWhitespaceValidator]],
      case_subject: ["", [Validators.required, this.noWhitespaceValidator]],
      case_status_id: ["", Validators.required],
      case_classification_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_prayer: [""],
      case_description: [""],
      ref_case_no: [""],
      case_affidavit_field: ["No"],
      case_affidavit_due_date: [""],
      case_affidavit_filing_date: [""],
      reason_affidavit_filing: [""],
      case_remark: [""],
      case_file_no: [""],
      case_counsel_id: [[]],
      case_rejoinder: ["No"],
      case_rejoinder_date: [""],
      case_sun_org_id: ["", [Validators.required, this.noWhitespaceValidator]],
      case_doc_type: [""],
      case_doc_name: [""],
      created_by: [this.username],
      nextDate: [""],
      previousDate: [""],
      complianceDate: [""],
      disposedDate: [""],
      files: []
    });
  }

  clickFunctionMain() {    
    this.isValidFormSubmittedMain = false;
    this.saveFormMain.markAllAsTouched();
    if (this.saveFormMain.invalid) {
      this.toast.showError(
        'Required fields are missing.'
      );
      this.isValidFormSubmittedMain = true;
    } else {
      if (this.caseid) {
        let caseData = { ...this.saveFormMain.value, updated_by: this.username }
        delete caseData.created_by;
        this.CaseS
          .updateCase(caseData, this.caseid)
          .subscribe((data: any) => {
            this.toast.showSuccess(
              'Congratulation!, Case has been updated.'
            );
            this.location.back();
          });
      } else {
        this.CaseS
          .saveCase(this.saveFormMain.value)
          .subscribe((data: any) => {
            if (data.statuscode === 200) {
              this.toast.showSuccess(
                'Congratulation!, Case has been submited.'
              );
              this.saveFormMain.reset();
              this.router.navigate(['dashboard', 'case-list'])
            }
            else {
              this.toast.showError('Oops! Case no., Court and Case year is all ready exist!');
            }
          });
      }
    }
  }

  // uploadDocument($event: any) {
  //   let file = $event.target.files;
  //   if (
  //     file[0].type == 'image/png' ||
  //     file[0].type == 'image/jpg' ||
  //     file[0].type == 'image/jpeg' ||
  //     file[0].type == 'application/pdf'
  //   ) {
  //     if (parseInt(file[0].size) > 5097152) { }
  //     else {
  //       const date = 'Wed Feb 20 2019 00:00:00 GMT-0400 (Atlantic Standard Time)';
  //       const time = '7:00 AM';
  //       this.CaseS.uploadFile(file[0]).subscribe((data: any) => {
  //         let files = this.saveFormMain.get('files').value() || []
  //         files.push(data?.body)
  //         this.saveFormMain.get('files').value(files)
  //         this.saveFormMain.get('files').updateValueAndValidity()
  //       })
  //     }
  //   }
  // }
  
  uploadDocument($event: any) {
    const files = $event.target.files;
    this.errorMessages = []; // Reset previous error messages

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg' ||
            file.type === 'application/pdf'
        ) {
            if (file.size > 2097152) { // 2 MB in bytes
                this.errorMessages.push(`${file.name} exceeds 2 MB limit.`);
                continue; // Skip to the next file
            } else {
                this.uploadSingleFile(file);
            }
        } else {
            this.errorMessages.push(`${file.name} is not a valid file type.`);
        }
    }
}

uploadSingleFile(file: File) {
    const uploadReq = this.CaseS.uploadFile(file);
    
    uploadReq.subscribe({
        next: (data: any) => {
            let files = this.saveFormMain.get('files').value || [];
            files = files.filter((file: any) => file !== undefined);
            files.push(data?.body);
            
            this.saveFormMain.get('files').setValue(files);
            
            
            this.saveFormMain.get('files').updateValueAndValidity();
        },
        error: (err) => {
            // Handle error case here
            console.error('Upload failed:', err);
        },
        complete: () => {
            this.uploadProgress = 0; // Reset progress after upload completion
        }
    });

    // Simulate progress for demonstration purposes
    const interval = setInterval(() => {
        this.uploadProgress += 10; // Increase progress
        if (this.uploadProgress >= 100) {
            clearInterval(interval); // Stop when complete
        }
    }, 100);
}


  get main() {
    return this.saveFormMain.controls;
  }

  bindPetitioner(id: any) {
    if (this.petitionerdata.length < 0) {
      this.getDataPet.map((item: any) => {
        if (item._id === id) {
          this.petitionerdata.push(item)
        }
      })
    }
    else {
      this.getDataPet.map((item: any) => {
        if (item._id === id) {
          let petitioner = false
          this.petitionerdata.map((data: any) => {
            if (data._id === id) {
              petitioner = true
            }
          })
          if (!petitioner) {
            this.petitionerdata.push(item)
          }
        }
      })
    }
    this.myControl.reset()
  }

  reason_filingvisibility(value: boolean) {
    this.reason_filing = value
  }

  writ_Status(status: any) {
    if (status === 'inline') {
      this.writ_statusCondition = true
      this.saveFormMain.get('case_no').setValidators([Validators.required]);
      this.saveFormMain.updateValueAndValidity()
    }
    else {
      this.writ_statusCondition = false
      this.saveFormMain.get('case_no').clearValidators()
      this.saveFormMain.updateValueAndValidity()
    }
  }


  pet_type(type: any) {
    if (type === 'Employee') {
      this.pet_typeCondition = true
      this.saveFormPetitioner.reset();
      this.saveFormPetitioner.get('pet_employee').setValue(type)
    }
    else {
      this.pet_typeCondition = false
      this.saveFormPetitioner.reset();
      this.saveFormPetitioner.get('pet_employee').setValue(type)
    }
  }


  respondent_type(type: any) {
    if (type === 'Employee') {
      this.res_typeCondition = true
      this.saveFormRespondent.reset();
      this.saveFormRespondent.get('res_employee').setValue(type)
    }
    else {
      this.res_typeCondition = false
      this.saveFormRespondent.reset();
      this.saveFormRespondent.get('res_employee').setValue(type)
    }
  }



  filter(data: any) {
    this.petiById = []
    let petitionerData = this.saveFormMain.value.case_petitioner_id
    for (let i of petitionerData) {
      this.MasterS
        .getPetiByIdData({
          case_petitioner_id: i,
        })
        .subscribe((data: any) => {
          this.petiById.push(data.resultData[0]);
        });
    }
  }

  filterRespondent(data: any) {
    this.resById = []
    let respondentData = this.saveFormMain.value.case_respondent_id
    for (let i of respondentData) {
      this.MasterS
        .getResByIdData({
          case_respondent_id: i,
        })
        .subscribe((data: any) => {
          this.resById.push(data.resultData[0]);
        });
    }

  }

  filterCouncel(data: any) {
    this.councelById = []
    let councelData = this.saveFormMain.value.case_counsel_id
    for (let i of councelData) {
      this.MasterS
        .getCouncelByIdData({
          case_counsel_id: i,
        })
        .subscribe((data: any) => {
          this.councelById.push(data.resultData[0]);
        });
    }
  }

  prevent() {
    return false;
  }

  openModal(controlName: string, iterable: any[], lable: string, nameKey: string = 'name') {
    this.spinner.show();
    this.isRendering = true;
    this.data.lable = lable;
    this.data.controlName = controlName;
    this.data.nameKey = nameKey;
    this.data.iterable = iterable;
    this.filteredData = iterable;
    this.totalPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
    this.paginateData();
    this.buffer[controlName] = this.saveFormMain.controls[controlName].value || [];

    this.cdr.detectChanges();
    this.ngZone.onStable.asObservable().subscribe(() => {
      if (this.isRendering) {
        this.isRendering = false;
        this.spinner.hide();
      }
    });
  }

  paginateData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedData = this.filteredData.slice(start, end);
    this.pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      this.pages.push(i);
    }

    if (this.totalPages <= 7) {
      this.displayPages = this.pages;
      this.showDotsBefore = false;
      this.showDotsAfter = false;
    } else {
      this.displayPages = [];
      const start = Math.max(this.currentPage - 2, 1);
      const end = Math.min(this.currentPage + 2, this.totalPages);
      if (start > 1) {
        this.showDotsBefore = true;
        this.displayPages.push(1);
      }
      for (let i = start; i <= end; i++) {
        this.displayPages.push(i);
      }
      if (end < this.totalPages) {
        this.showDotsAfter = true;
        this.displayPages.push(this.totalPages);
      }

      if (this.currentPage <= 4) {
        this.showDotsBefore = false;
        this.showDotsAfter = true;
      } else if (this.totalPages - this.currentPage <= 3) {
        this.showDotsBefore = true;
        this.showDotsAfter = false;
      } else {
        this.showDotsBefore = true;
        this.showDotsAfter = true;
      }
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginateData();
    }
  }

  goToPage(pageNo: number) {
    if (pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.paginateData();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginateData();
    }
  }

  onSearch(query: string) {
    this.searchQuery = query;
    this.filteredData = this.data.iterable;
    this.filteredData = this.filteredData.filter((item: any) =>
      item[this.data.nameKey].toLowerCase().includes(query.toLowerCase())
    );
    this.totalPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
    this.currentPage = 1;
    this.paginateData();
  }

  addToBuffer(controlName: string, id: string) {
    this.buffer[controlName] = this.buffer[controlName] || [];
    let availability = this.buffer[controlName].includes(id)
    if (!availability) {
      this.buffer[controlName].push(id);
    } else {
      let itemInd = this.buffer[controlName].indexOf(id)
      this.buffer[controlName].splice(itemInd, 1);
    }
  }

  saveData(controlName: string) {
    this.saveFormMain.controls[controlName].patchValue(this.buffer[controlName])
    this.currentPage = 1;
    this.buffer = {};
    this.data = {}
    this.searchinp.nativeElement.value = '';
    if (controlName == 'case_counsel_id') {
      this.getCouncelById()
    } else if (controlName == 'case_petitioner_id') {
      this.getPetiById();
    } else if (controlName == 'case_respondent_id') {
      this.getResById();
    }
  }

  reset() {
    this.searchinp.nativeElement.value = '';
    this.currentPage = 1;
    this.buffer = {};
    this.data = {}
  }

  unknownRef() {
    this.refrenceUk = !this.refrenceUk;
    this.saveFormMain.get('ref_case_no').setValue('');
  }
}
