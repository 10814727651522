import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { CaseService } from 'src/app/services/case.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resource-document-add',
  templateUrl: './resource-document-add.component.html',
  styleUrls: ['./resource-document-add.component.css']
})
export class ResourceDocumentAddComponent implements OnInit {

  saveForm: any;
  isValidFormSubmitted!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private ho: MasterService,
    private toast: ToasterService,
    private CaseS: CaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.onforminit();
  }

  onforminit() {
    this.saveForm = this.fb.group({
      name: ['', Validators.required],
      upload: ['', Validators.required],
      document_description: [''],
    });
  }
  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.ho
        .saveResourceDoc(this.saveForm.value)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          // window.location.href = '/resource-document-list';
          this.router.navigate(['/dashboard/resource-document-list']);
          // this.saveForm.reset()
        });
    }
  }

  uploadDocument($event: any) {
    let file = $event.target.files;
    if (true
      // file[0].type == 'image/png' ||
      // file[0].type == 'image/jpg' ||
      // file[0].type == 'image/jpeg' ||
      // file[0].type == 'application/pdf'
    ) {


      if (parseInt(file[0].size) > 5097152) {
        this.toast.showError(
          'Oops! file to large error'
        );
      }
      else {
        const date = 'Wed Feb 20 2019 00:00:00 GMT-0400 (Atlantic Standard Time)';
        const time = '7:00 AM';
        this.CaseS.uploadFile(file[0]).subscribe((data: any) => {

          this.saveForm.get('upload').setValue(data?.body)
          this.saveForm.get('upload').updateValueAndValidity()
        }, err => {
          this.toast.showError(
            'Oops! File Type  should be .gif, .jpg, .jpeg, .png, .pdf'
          );
        }
        )

      }
    }
    else { }

  }

  get f() {
    return this.saveForm.controls;
  }

}
