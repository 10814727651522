import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(value: string, length: number): unknown {
    if (value?.length > length) {
      return value?.slice(0, length) + "...";
    }
    return value;
  }

}
