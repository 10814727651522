<div id="content" class="app-content" role="main">

    <div class="col-sm-12" style="margin:0px;padding: 0px;">

        <div class="wrapper-md">
            <div class="panel ">
                <div class="panel-body">
                    <!-- <form role="form" [formGroup]="addroleform" > -->
                    <form [formGroup]="saveForm" (ngSubmit)="clickFunction()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label font-weight-600">Role Name</label>
                                    <input type="text" class="form-control validate" formControlName="role_name">
                                    <div *ngIf="f.role_name.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.role_name.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label font-weight-600">Role Description</label>
                                    <input type="text" class="form-control validate" formControlName="description">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Module</th>
                                                <th class="text-center">Create</th>
                                                <th class="text-center">View</th>
                                                <th class="text-center">Edit</th>
                                                <th class="text-center">Delete</th>
                                                <!-- <th class="text-center">Verifier</th> -->
                                                <!-- <th class="text-center">Approver</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="font-bold">Administration</td>

                                                <td class="text-center" colspan="4">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="administration">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Case</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>

                                            </tr>


                                            <tr>
                                                <td class="font-bold">Case Details</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDetailsCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDetailsView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDetailsEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td class="font-bold">Case Timeline</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseTimeLineView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td class="font-bold">Case Task</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseTaskCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseTaskView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseTaskEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseTaskDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td class="font-bold">Case Document</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDocumentCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDocumentView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDocumentEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="caseDocumentDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td class="font-bold">Report</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="reportView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" [attr.disabled]="true">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Petitioner</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="petitionerCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="petitionerView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="petitionerEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="petitionerDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Respondent</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="respondentCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="respondentView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="respondentEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="respondentDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Counsel</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="counselCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="counselView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="counselEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="counselDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Users</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="userCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="userView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="userEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="userDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Role And Permission</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="roleCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="roleView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="roleEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="roleDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-bold">Master</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="masterCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="masterView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="masterEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="masterDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="font-bold">Resources</td>

                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="resourceCreate">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="resourceView">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="resourceEdit">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="checkbox g-my-0">
                                                        <label class="i-checks i-checks">
                                                            <input type="checkbox" formControlName="resourceDelete">
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>



                        <div class="row m-t-md">
                            <div class="col-md-12">
                                <div class="button_group_fotter">
                                    <a class="btn btn-dark" routerLink="/dashboard/role-permission-list"
                                        routerLinkActive="active">Cancel</a>
                                    <button class="btn btn-primary" type="submit"> Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>