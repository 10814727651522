<div id="content" class="app-content" role="main">
    <div class="app-content-body ">

        <div class="bg-blue lter b-b wrapper-md">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="m-n font-thin text-white" *ngIf="report_type == true">विभिन्न न्यायालयों में लम्बित
                        संगठनवार वादों की अद्दतन स्थिति (a-1)</h4>
                    <h4 class="m-n font-thin text-white" *ngIf="report_type == false">विभिन्न न्यायालयों में लम्बित
                        संगठनवार अवमाननावादों की अद्दतन स्थिति (b-1)</h4>

                </div>
            </div>
        </div>

        <div class="wrapper-md">
            <div class="panel b-a">
                <div class="panel-body">
                    <div id="print-section">
                        <div class="page-footer wrapper-md  bg-blue">
                            <div class="footer-info">
                                <div class="bootom-cont">

                                </div>
                            </div>
                        </div>
                        <div class="">
                            <table style="width:100%">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="page-header-space"></div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="page">
                                                <div class=" page-header  head-panel">
                                                    <div class="b-light font-500 poppins">
                                                        <div class="thum-cont">

                                                            <p *ngIf="report_type == true"
                                                                style="margin:0px; text-align: end;">
                                                                मासिक सूचना
                                                                प्रपत्र- अ-1 </p>
                                                            <p *ngIf="report_type== false"
                                                                style="margin:0px; text-align: end;"> मासिक सूचना
                                                                प्रपत्र- ब-1 </p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class=" page-header  head-panel">
                                                    <div class="b-light font-500 poppins">
                                                        <div class="invice-head">
                                                            <div class="thum-cont">
                                                                <h4> सिंचाई विभाग उत्तर प्रदेश लखनऊ </h4>
                                                                <p> विभिन्न न्यायालयों में लम्बित संगठनवार वादों की
                                                                    अद्दतन स्थिति </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="rep"></div>
                                                <div class="rate-table">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th rowspan="6">क्र0 सं0</th>
                                                                <th rowspan="6">संगठन का नाम</th>
                                                                <th colspan="4">{{court_name}}</th>
                                                                <th colspan="26">शासन से सम्बन्धित अनुभाग</th>
                                                            </tr>
                                                            <tr>
                                                                <th rowspan="4">कुल वाद</th>
                                                                <th rowspan="4">दाखिल</th>
                                                                <th rowspan="4">शेष</th>
                                                                <th rowspan="4">आ0 नही</th>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="2">अनु0-01</th>
                                                                <th colspan="2">अनु0-02</th>
                                                                <th colspan="2">अनु0-03</th>
                                                                <th colspan="2">अनु0-04</th>
                                                                <th colspan="2">अनु0-05</th>
                                                                <th colspan="2">अनु0-06</th>
                                                                <th colspan="2">अनु0-07</th>
                                                                <th colspan="2">अनु0-08</th>
                                                                <th colspan="2">अनु0-09</th>
                                                                <th colspan="2">अनु0-10</th>
                                                                <th colspan="2">अनु0-11</th>
                                                                <th colspan="2">अनु0-12</th>
                                                                <th colspan="2">अनु0-13</th>
                                                            </tr>
                                                            <tr>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                                <th rowspan="2">कुल</th>
                                                                <th rowspan="2">शेष</th>
                                                            </tr>
                                                        </thead>
                                                        <thead>
                                                            <tr>
                                                                <th>1</th>
                                                                <th>2</th>
                                                                <th>3</th>
                                                                <th>4</th>
                                                                <th>5</th>
                                                                <th>6</th>
                                                                <th>7</th>
                                                                <th>8</th>
                                                                <th>9</th>
                                                                <th>10</th>
                                                                <th>11</th>
                                                                <th>12</th>
                                                                <th>13</th>
                                                                <th>14</th>
                                                                <th>15</th>
                                                                <th>16</th>
                                                                <th>17</th>
                                                                <th>18</th>
                                                                <th>19</th>
                                                                <th>20</th>
                                                                <th>21</th>
                                                                <th>22</th>
                                                                <th>23</th>
                                                                <th>24</th>
                                                                <th>25</th>
                                                                <th>26</th>
                                                                <th>27</th>
                                                                <th>28</th>
                                                                <th>29</th>
                                                                <th>30</th>
                                                                <th>31</th>
                                                                <th>32</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <ng-container *ngIf="uniqueOrg.length>0">
                                                                <ng-container *ngFor="let get of uniqueOrg;let i=index">
                                                                    <tr>
                                                                        <td>{{get.org_name}}</td>
                                                                    </tr>
                                                                    <ng-container *ngFor="let case of get;let i=index">
                                                                        <tr class="bd" *ngIf="case.sub_org_name">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{case.sub_org_name}}</td>
                                                                            <td>&nbsp;{{case.kul_wad}}
                                                                            </td>
                                                                            <td>&nbsp;{{case.dakhil}}</td>
                                                                            <td>&nbsp;{{case.sesh}}</td>
                                                                            <td>&nbsp;{{case.not_required}}</td>

                                                                            <td>&nbsp;{{case.anu_1}}</td>
                                                                            <td>&nbsp;{{case.ses_1}}</td>

                                                                            <td>&nbsp;{{case.anu_2}}</td>
                                                                            <td>&nbsp;{{case.ses_2}}</td>
                                                                            <td>&nbsp;{{case.anu_3}}</td>
                                                                            <td>&nbsp;{{case.ses_3}}</td>
                                                                            <td>&nbsp;{{case.anu_4}}</td>
                                                                            <td>&nbsp;{{case.ses_4}}</td>
                                                                            <td>&nbsp;{{case.anu_5}}</td>
                                                                            <td>&nbsp;{{case.ses_5}}</td>
                                                                            <td>&nbsp;{{case.anu_6}}</td>
                                                                            <td>&nbsp;{{case.ses_6}}</td>
                                                                            <td>&nbsp;{{case.anu_7}}</td>
                                                                            <td>&nbsp;{{case.ses_7}}</td>
                                                                            <td>&nbsp;{{case.anu_8}}</td>
                                                                            <td>&nbsp;{{case.ses_8}}</td>
                                                                            <td>&nbsp;{{case.anu_9}}</td>
                                                                            <td>&nbsp;{{case.ses_9}}</td>
                                                                            <td>&nbsp;{{case.anu_10}}</td>
                                                                            <td>&nbsp;{{case.ses_10}}</td>
                                                                            <td>&nbsp;{{case.anu_11}}</td>
                                                                            <td>&nbsp;{{case.ses_11}}</td>
                                                                            <td>&nbsp;{{case.anu_12}}</td>
                                                                            <td>&nbsp;{{case.ses_12}}</td>
                                                                            <td>&nbsp;{{case.anu_13}}</td>
                                                                            <td>&nbsp;{{case.ses_13}}</td>

                                                                            <!-- <td>&nbsp;</td> -->
                                                                        </tr>
                                                                    </ng-container>
                                                                    <tr class="bd">
                                                                        <td>&nbsp;</td>
                                                                        <td>योग</td>
                                                                        <td>&nbsp;{{get.kul}}</td>


                                                                        <td>&nbsp;{{get.dakhil}}</td>
                                                                        <td>&nbsp;{{get.sesh}}</td>
                                                                        <td>&nbsp;{{get.not_required}}</td>

                                                                        <td>&nbsp;{{get.anu_1}}</td>
                                                                        <td>&nbsp;{{get.ses_1}}</td>

                                                                        <td>&nbsp;{{get.anu_2}}</td>
                                                                        <td>&nbsp;{{get.ses_2}}</td>


                                                                        <td>&nbsp;{{get.anu_3}}</td>
                                                                        <td>&nbsp;{{get.ses_3}}</td>


                                                                        <td>&nbsp;{{get.anu_4}}</td>
                                                                        <td>&nbsp;{{get.ses_4}}</td>

                                                                        <td>&nbsp;{{get.anu_5}}</td>
                                                                        <td>&nbsp;{{get.ses_5}}</td>

                                                                        <td>&nbsp;{{get.anu_6}}</td>
                                                                        <td>&nbsp;{{get.ses_6}}</td>

                                                                        <td>&nbsp;{{get.anu_7}}</td>
                                                                        <td>&nbsp;{{get.ses_7}}</td>

                                                                        <td>&nbsp;{{get.anu_8}}</td>
                                                                        <td>&nbsp;{{get.ses_8}}</td>
                                                                        <td>&nbsp;{{get.anu_9}}</td>
                                                                        <td>&nbsp;{{get.ses_9}}</td>
                                                                        <td>&nbsp;{{get.ses_10}}</td>
                                                                        <td>&nbsp;{{get.anu_10}}</td>

                                                                        <td>&nbsp;{{get.ses_11}}</td>
                                                                        <td>&nbsp;{{get.anu_11}}</td>

                                                                        <td>&nbsp;{{get.ses_12}}</td>
                                                                        <td>&nbsp;{{get.anu_12}}</td>
                                                                        <td>&nbsp;{{get.ses_13}}</td>
                                                                        <td>&nbsp;{{get.anu_13}}</td>
                                                                    </tr>
                                                                </ng-container>

                                                            </ng-container>



                                                            <tr class="bd">
                                                                <td>&nbsp;</td>
                                                                <td class="font-weight-600">महायोग</td>
                                                                <td>&nbsp;{{mkul}}</td>


                                                                <td>&nbsp;{{mdakhil}}</td>
                                                                <td>&nbsp;{{msesh}}</td>
                                                                <td>&nbsp;{{mnot_required}}</td>

                                                                <td>&nbsp;{{manu_1}}</td>
                                                                <td>&nbsp;{{mses_1}}</td>

                                                                <td>&nbsp;{{manu_2}}</td>
                                                                <td>&nbsp;{{mses_2}}</td>


                                                                <td>&nbsp;{{manu_3}}</td>
                                                                <td>&nbsp;{{mses_3}}</td>


                                                                <td>&nbsp;{{manu_4}}</td>
                                                                <td>&nbsp;{{mses_4}}</td>

                                                                <td>&nbsp;{{manu_5}}</td>
                                                                <td>&nbsp;{{mses_5}}</td>

                                                                <td>&nbsp;{{manu_6}}</td>
                                                                <td>&nbsp;{{mses_6}}</td>

                                                                <td>&nbsp;{{manu_7}}</td>
                                                                <td>&nbsp;{{mses_7}}</td>

                                                                <td>&nbsp;{{manu_8}}</td>
                                                                <td>&nbsp;{{mses_8}}</td>

                                                                <td>&nbsp;{{manu_9}}</td>
                                                                <td>&nbsp;{{mses_9}}</td>

                                                                <td>&nbsp;{{manu_10}}</td>
                                                                <td>&nbsp;{{mses_10}}</td>

                                                                <td>&nbsp;{{manu_11}}</td>
                                                                <td>&nbsp;{{mses_11}}</td>

                                                                <td>&nbsp;{{manu_12}}</td>
                                                                <td>&nbsp;{{mses_12}}</td>

                                                                <td>&nbsp;{{manu_13}}</td>
                                                                <td>&nbsp;{{mses_13}}</td>
                                                            </tr>
                                                            <!-- <tr class="bd">
                                                                <td></td>
                                                                <td class="font-weight-600">महायोग</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr> -->



                                                            <!-- <tr class="bd">
                                                                <td colspan="9" class="font-weight-600">TOTAL:</td>
                                                                <td class="font-weight-600">
                                                                    ₹gfhjt
                                                                </td>
                                                            </tr> -->

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div class="page-footer-space"></div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary" [useExistingCss]="true" printTitle="अ-1" printSectionId="print-section"
                ngxPrint styleSheetFile="assets\css\invoice-print.css">Download as Pdf</button>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>