import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-case-type-update',
  templateUrl: './case-type-update.component.html',
  styleUrls: ['./case-type-update.component.css']
})
export class CaseTypeUpdateComponent implements OnInit {
  getData: any;
  id: any;
  EditData: any;
  saveForm!: FormGroup;
  isValidFormSubmitted!: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private MasterS: MasterService,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      court_id: ['', Validators.required],
      case_type_name: ['', Validators.required],
    })
    this.MasterS
      .editCaseType(this.id)
      .subscribe((data: any) => {
        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
      })
    this.viewCourtdata();
  }
  viewCourtdata() {
    this.MasterS.GetCourtdata().subscribe((data: any) => {
      this.getData = data.resultData;
    });
  }
  get f() {
    return this.saveForm.controls;
  }
  onforminit(userData: any) {
    this.saveForm.patchValue(userData);
  }
  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      this.MasterS
        .updateCaseType(this.saveForm.value, this.id)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          // window.location.href = '/case-type-list';
          this.router.navigate(['/dashboard/case-type-list']);
          // this.saveForm.reset()
        });
    }
  }
}
