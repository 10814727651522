import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-se-update',
  templateUrl: './se-update.component.html',
  styleUrls: ['./se-update.component.css']
})
export class SeUpdateComponent implements OnInit {
  id: any;
  EditData: any;
  saveForm!: FormGroup;
  isValidFormSubmitted!: boolean;
  getData: any;
  constructor(
    private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      sub_org_id: ['', Validators.required],
      se_name: ['', Validators.required],
    })
    this.MasterS
      .editSE(this.id)
      .subscribe({
        next: (data: any) => {
          this.EditData = data.updateData[0];
          this.onforminit(this.EditData)
          this.spinner.hide()
        },
        error: (error: any) => {
          this.spinner.hide()
        }
      });
    this.viewdataOrgCe();
  }
  viewdataOrgCe() {
    this.MasterS.getSubOrAllData().subscribe((data: any) => {
      this.getData = data.resultData;
    });
  }
  onforminit(userData: any) {
    this.saveForm.patchValue(userData)
    this.saveForm.get('sub_org_id')?.disable();
  }
  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      this.MasterS
        .updateSE(this.saveForm.value, this.id)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          this.router.navigate(['/dashboard/se-list']);
        });
    }
  }
  get f() {
    return this.saveForm.controls;
  }
}
