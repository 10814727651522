<div id="content" class="app-content" role="main">
    <div class="bg-light lter b-b wrapper-md">
        <div class="row">
            <div class="col-sm-6 col-xs-12">
                <h1 class="m-n font-thin h3 text-black">Create Vehicle</h1>
                <small class="text-muted">Please Fill all the necessary details</small>
            </div>
            <div class="col-sm-6 text-right hidden-xs"> <a class="btn m-b-xs w-xs btn-sm btn-success" href="">Vehicle</a> </div>
        </div>
    </div>
    <div class="wrapper-md">
        <div class="panel b-a">
          <div class="panel-body">
            <form role="form" method="post" action="" autocomplete="off">
              <div class="row">
                <div class="col-md-12">
                  <legend> <span class="font-weight-600">Vehicle Information</span> </legend>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Dealor Code:</label>
                    <select class="form-control dealer_code" name="dealer_code">
                      <option value=''>Select</option>
                      
                      <option value="1">One</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Fin No:</label>
                    <input type="text" class="form-control" name="fin_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">City Code:</label>
                    <input type="text" class="form-control" name="city_code">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Model Code:</label>
                    <select class="form-control model_code" name="model_code">
                      <option value=''>Select</option>
                      
                      <option value="one">One</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Invoice Type:</label>
                    <input type="text" class="form-control" name="invoice_type">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Color Code:</label>
                    <input type="text" class="form-control color_code" name="color_code" readonly>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Chassis Prefix:</label>
                    <input type="text" class="form-control" name="chassis_prefix">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Chassis No:</label>
                    <input type="text" class="form-control" name="chassis_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Engin No:</label>
                    <input type="text" class="form-control" name="engin_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Invoice Date:</label>
                    <input type="text" class="form-control invoice_date" name="invoice_date"placeholder="mm/dd/yyyy">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Invoice Amt:</label>
                    <input type="text" class="form-control" name="invoice_amount">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Order Category:</label>
                    <input type="text" class="form-control" name="order_category">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Plant:</label>
                    <input type="text" class="form-control" name="plant">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Transport Reg No:</label>
                    <input type="text" class="form-control" name="transport_reg_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Tin:</label>
                    <input type="text" class="form-control" name="tin">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Allotment No:</label>
                    <input type="text" class="form-control" name="allotment_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Sent By:</label>
                    <input type="text" class="form-control" name="sent_by">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Transportation Name:</label>
                    <input type="text" class="form-control" name="transportation_name">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Consignment No:</label>
                    <input type="text" class="form-control" name="consignment_no">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Email Id:</label>
                    <input type="text" class="form-control" name="email">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Financer:</label>
                    <input type="text" class="form-control" name="financer">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label font-weight-600">Remark:</label>
                    <input type="text" class="form-control" name="remark">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input type="submit" name="submit" value="Submit" class="btn m-b-xs w-sm btn-success"> </div>
              </div>
            </form>
          </div>
        </div>
      </div>


</div>