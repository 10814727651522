import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-a-one-report',
  templateUrl: './a-one-report.component.html',
  styleUrls: ['./a-one-report.component.css']
})
export class AOneReportComponent implements OnInit {
  form: FormGroup;
  getDataO: any[] = [];
  getDataS: any[] = [];
  uniqueOrg: any[] = [];
  subOrgDataByOrg: any[] = [];
  getDataC: any[] = [];
  makayog_data: any = {};
  ids: any;
  court_id: any;
  holdedData: any[] = [];

  court_name: string = 'मा0 उच्च न्यायालय लखनऊ';

  mkul = 0;
  mdakhil = 0;
  msesh = 0;
  mnot_required = 0;
  manu_1 = 0;
  manu_2 = 0;
  manu_3 = 0;
  manu_4 = 0;
  manu_5 = 0;
  manu_6 = 0;
  manu_7 = 0;
  manu_8 = 0;
  manu_9 = 0;
  manu_10 = 0;
  manu_11 = 0;
  manu_12 = 0;
  manu_13 = 0;
  mlagu_nhi = 0;
  mparti = 0;


  mses_1 = 0;
  mses_2 = 0;
  mses_3 = 0;
  mses_4 = 0;
  mses_5 = 0;
  mses_6 = 0;
  mses_7 = 0;
  mses_8 = 0;
  mses_9 = 0;
  mses_10 = 0;
  mses_11 = 0;
  mses_12 = 0;
  mses_13 = 0;
  rowData = [
    {
      label: 'संगठन 1', // Organization 1
      farmerUnderNfCount: 150,
      areaUnderNf: 240,
      children: [
        {
          label: 'उप संगठन अ', // Sub-organization A
          farmerUnderNfCount: 100,
          areaUnderNf: 150
        },
        {
          label: 'उप संगठन ब', // Sub-organization B
          farmerUnderNfCount: 50,
          areaUnderNf: 90
        }
      ]
    },
    {
      label: 'संगठन 2', // Organization 2
      farmerUnderNfCount: 200,
      areaUnderNf: 300,
      children: [
        {
          label: 'उप संगठन अ', // Sub-organization A
          farmerUnderNfCount: 120,
          areaUnderNf: 180
        },
        {
          label: 'उप संगठन ब', // Sub-organization B
          farmerUnderNfCount: 80,
          areaUnderNf: 120
        }
      ]
    },
    {
      label: 'संगठन 3', // Organization 3
      farmerUnderNfCount: 250,
      areaUnderNf: 400,
      children: [
        {
          label: 'उप संगठन अ', // Sub-organization A
          farmerUnderNfCount: 150,
          areaUnderNf: 250
        },
        {
          label: 'उप संगठन ब', // Sub-organization B
          farmerUnderNfCount: 100,
          areaUnderNf: 150
        }
      ]
    }
  ];

  all_selected_dep: any[] = [{ org_name: 'यांत्रिक संगठन (Mechanical)', department_id: "62f20818f187b8a21ec4c05c" },
  { org_name: 'सिविल संगठन (Civil)', department_id: "62f20808f187b8a21ec4c059" }
  ];
  constructor(private httpService: CaseService,
    private MasterS: MasterService,
    private router: Router,
    private spinner: NgxSpinnerService) {
    this.form = new FormGroup({
      case_petitioner_id: new FormControl(['62f20818f187b8a21ec4c05c', '62f20808f187b8a21ec4c059']) // IDs of the default selected options
    });
    this.preloadSelectedDepartments();
  }

  colDefs: any[] = [
    {
      headerName: 'मासिक सूचना प्रपत्र- अ-1',
      children: [
        {
          headerName: 'सिंचाई विभाग उत्तर प्रदेश लखनऊ विभिन्न न्यायालयों में लम्बित संगठनवार वादों की अद्दतन स्थिति माह 01-2024',
          children: [
            {
              headerName: 'संगठन का नाम',
              children: [
                {
                  field: "label",
                  minWidth: 150,
                  resizable: true,
                  floatingFilter: true,
                  headerName: 'Farmer Level'
                },
              ],
            },
            {
              headerName: "मा0 अन्य न्यायालय",
              children: [
                { field: "label", minWidth: 70, headerName: 'कुल वाद', resizable: true, floatingFilter: true },
                { field: "farmerUnderNfCount", headerName: 'दाखिल', minWidth: 70, resizable: true, floatingFilter: true },
                { field: "label", minWidth: 70, headerName: 'शेष', resizable: true, floatingFilter: true },
                { field: "farmerUnderNfCount", headerName: 'आ0 न0', minWidth: 70, resizable: true, floatingFilter: true }

              ],
            },
            {
              headerName: "Group 2",
              children: [
                { field: "label", minWidth: 170, resizable: true, floatingFilter: true },
                { field: "farmerUnderNfCount", minWidth: 170, resizable: true, floatingFilter: true },
                { field: "areaUnderNf", minWidth: 170, resizable: true, floatingFilter: true }
              ],
            }
          ],
        }
      ]
    }
  ];


  ngOnInit(): void {
    this.orgData();
    this.courtData();
    this.getAOne();
  }

  selectCourt(e: any) {
    this.court_id = e.target.value;
    let item = this.getDataC.find((item: any) => item._id == e.target.value);
    this.court_name = item.court_name;
  }

  secData() {
    this.MasterS.GetSecdata().subscribe((data: any) => {
      this.getDataS = data.resultData;
    });
  }

  orgData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
  }

  getSubOrgByOrgd(id: string) {
    // console.log("targert departmemnt value")

    this.MasterS
      .getSubOrgByOrg({
        case_sun_org_id: id,
      })
      .subscribe((data: any) => {

        this.subOrgDataByOrg = data.result;
      });
  }

  getAllCase(): void {
    let userID = localStorage.getItem("id");
    this.httpService.GetCaseDatas(userID).subscribe({
      next: (resp: any) => {

        if (resp?.resultData?.length > 0) {
          let data = resp?.resultData.filter((item: any) => item.case_court_id.court_name == 'मा0 उच्च न्यायालय इलाहाबाद');

          this.getDataO.forEach((d: any) => {
            let case_contempt_total = 0;
            let case_contempt_no = 0;
            let case_contempt_yes = 0;

            let lagu_nhi = 0;
            let ca_1 = 0;
            let ca_2 = 0;
            let ca_3 = 0;
            let ca_4 = 0;
            let ca_5 = 0;
            let ca_6 = 0;
            let ca_7 = 0;
            let ca_8 = 0;
            let ca_9 = 0;
            let ca_10 = 0;
            let ca_11 = 0;
            let ca_12 = 0;
            let ca_13 = 0;
            let anupati = 0;

            let body: any = {};
            // this.getSubOrgByOrgd(d.case_orgnaization_id._id);
            this.MasterS
              .getSubOrgByOrg({
                case_sun_org_id: d._id,
              })
              .subscribe((data: any) => {

                this.subOrgDataByOrg = data.result;
                data.result.forEach((sub_org: any) => {


                  if (sub_org.sub_org_code == d.sub_org_code) {
                    data.forEach((v: any) => {
                      // console.log(d.org_name, ":   ", v.case_orgnaization_id.org_name)
                      if (d.org_name == v.case_orgnaization_id.org_name) {
                        // console.log("DD: ", v)
                        // if()
                        body['org_name'] = d.org_name;
                        case_contempt_total += 1;
                        if (v.case_contempt == 'No') {
                          case_contempt_no += 1;
                        } else if (v.case_contempt == 'Yes') {
                          case_contempt_yes += 1;
                        }
                        this.getDataS.forEach((ss: any) => {
                          if (ss.section_code == v.case_section_id.section_code) {
                            if (ss.section_code == '483125') {
                              lagu_nhi += 1;
                            } else if (ss.section_code == '274829') {
                              ca_1 += 1;
                            } else if (ss.section_code == '555147') {
                              ca_2 += 1;
                            } else if (ss.section_code == '713693') {
                              ca_3 += 1;
                            } else if (ss.section_code == '698630') {
                              ca_4 += 1;
                            } else if (ss.section_code == '921757') {
                              ca_5 += 1;
                            } else if (ss.section_code == '690196') {
                              ca_6 += 1;
                            } else if (ss.section_code == '744691') {
                              ca_7 += 1;
                            } else if (ss.section_code == '358418') {
                              ca_8 += 1;
                            } else if (ss.section_code == '434743') {
                              ca_9 += 1;
                            } else if (ss.section_code == '846473') {
                              ca_10 += 1;
                            } else if (ss.section_code == '681022') {
                              ca_11 += 1;
                            } else if (ss.section_code == '487178') {
                              ca_12 += 1;
                            } else if (ss.section_code == '848481') {
                              ca_13 += 1;
                            } else if (ss.section_code == '810679') {
                              anupati += 1;
                            }
                          }
                        })
                      }


                    })
                  }
                })
              });




            body['case_contempt_total'] = case_contempt_total;
            body['case_contempt_no'] = case_contempt_no;
            body['case_contempt_yes'] = case_contempt_yes;

            body['lagu_nhi'] = lagu_nhi;
            body['ca_1'] = ca_1;
            body['ca_2'] = ca_2;
            body['ca_3'] = ca_3;
            body['ca_4'] = ca_4;
            body['ca_5'] = ca_5;
            body['ca_6'] = ca_6;
            body['ca_7'] = ca_7;
            body['ca_8'] = ca_8;
            body['ca_9'] = ca_9;
            body['ca_10'] = ca_10;
            body['ca_11'] = ca_11;
            body['ca_12'] = ca_12;
            body['ca_13'] = ca_13;
            body['anupati'] = anupati;

            this.uniqueOrg.push(body);

          })

          data.case_contempt
        }
      },
      error: (err: any) => {

      }
    });
  }

  courtData() {
    this.MasterS.GetCourtdata().subscribe((data: any) => {

      this.getDataC = data.resultData;
    });
  }

  goToPrintPage() {
    const queryParams = {
      arrayParam: JSON.stringify(this.all_selected_dep),
      court_id: this.court_id,
      court_name: this.court_name,
      type: 'a'
    };

    this.router.navigate(['/dashboard/print-report'], { queryParams });
  }

  getAOne(id?: any, court_id?: any) {
    this.court_id = court_id ? court_id : '6303473d978a84e1e6231304';
    this.spinner.show();
    this.httpService.getAOne({ department_id: this.all_selected_dep, case_court_id: this.court_id }).subscribe({
      next: (resp: any) => {

        this.uniqueOrg = [];
        this.uniqueOrg = resp['data'];
        this.holdedData = [];
        resp['data'].filter((caseObj: any) => {
          // for (let data of caseObj) {
          let body: any = {};

          caseObj['kul'] = caseObj.reduce((acc: any, data: any) => acc + data.kul_wad, 0);
          body['kul'] = caseObj['kul'];
          caseObj['dakhil'] = caseObj.reduce((acc: any, data: any) => acc + data.dakhil, 0);
          body['dakhil'] = caseObj['dakhil'];
          caseObj['sesh'] = caseObj.reduce((acc: any, data: any) => acc + data.sesh, 0);
          body['sesh'] = caseObj['sesh'];

          caseObj['not_required'] = caseObj.reduce((acc: any, data: any) => acc + data.not_required, 0);
          body['not_required'] = caseObj['not_required'];

          caseObj['kul_wad'] = Number(caseObj?.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);
          body['kul_wad'] = caseObj['kul_wad'];
          caseObj['ses_1'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_1, 0);
          body['ses_1'] = caseObj['ses_1'];
          caseObj['ses_2'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_2, 0);
          body['ses_2'] = caseObj['ses_2'];
          caseObj['ses_3'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_3, 0);
          body['ses_3'] = caseObj['ses_3'];
          caseObj['ses_4'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_4, 0);
          body['ses_4'] = caseObj['ses_4'];
          caseObj['ses_5'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_5, 0);
          body['ses_5'] = caseObj['ses_5'];
          caseObj['ses_6'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_6, 0);
          body['ses_6'] = caseObj['ses_6'];
          caseObj['ses_7'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_7, 0);
          body['ses_7'] = caseObj['ses_7'];
          caseObj['ses_8'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_8, 0);
          body['ses_8'] = caseObj['ses_8'];
          caseObj['ses_9'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_9, 0);
          body['ses_9'] = caseObj['ses_9'];
          caseObj['ses_10'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_10, 0);
          body['ses_10'] = caseObj['ses_10'];
          caseObj['ses_11'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_11, 0);
          body['ses_11'] = caseObj['ses_11'];
          caseObj['ses_12'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_12, 0);
          body['ses_12'] = caseObj['ses_12'];
          caseObj['ses_13'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_13, 0);
          body['ses_13'] = caseObj['ses_13'];
          caseObj['anu_1'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_1, 0);
          body['anu_1'] = caseObj['anu_1'];
          caseObj['anu_2'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_2, 0);
          body['anu_2'] = caseObj['anu_2'];
          caseObj['anu_3'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_3, 0);
          body['anu_3'] = caseObj['anu_3'];
          caseObj['anu_4'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_4, 0);
          body['anu_4'] = caseObj['anu_4'];
          caseObj['anu_5'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_5, 0);
          body['anu_5'] = caseObj['anu_5'];
          caseObj['anu_6'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_6, 0);
          body['anu_6'] = caseObj['anu_6'];
          caseObj['anu_7'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_7, 0);
          body['anu_7'] = caseObj['anu_7'];
          caseObj['anu_8'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_8, 0);
          body['anu_8'] = caseObj['anu_8'];
          caseObj['anu_9'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_9, 0);
          body['anu_9'] = caseObj['anu_9'];
          caseObj['anu_10'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_10, 0);
          body['anu_10'] = caseObj['anu_10'];
          caseObj['anu_11'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_11, 0);
          body['anu_11'] = caseObj['anu_11'];
          caseObj['anu_12'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_12, 0);
          body['anu_12'] = caseObj['anu_12'];
          caseObj['anu_13'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_13, 0);
          body['anu_13'] = caseObj['anu_13'];
          // }


          this.holdedData.push(body);
          return caseObj
        });


        this.mkul = this.holdedData.reduce((acc: any, data: any) => acc + data.kul, 0);

        this.mdakhil = this.holdedData.reduce((acc: any, data: any) => acc + data.dakhil, 0);

        this.msesh = this.holdedData.reduce((acc: any, data: any) => acc + data.sesh, 0);


        this.mnot_required = this.holdedData.reduce((acc: any, data: any) => acc + data.not_required, 0);


        // caseObj['kul_wad'] = Number(this.holdedData.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);

        this.mses_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_1, 0);

        this.mses_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_2, 0);

        this.mses_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_3, 0);

        this.mses_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_4, 0);

        this.mses_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_5, 0);

        this.mses_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_6, 0);

        this.mses_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_7, 0);

        this.mses_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_8, 0);

        this.mses_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_9, 0);

        this.mses_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_10, 0);

        this.mses_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_11, 0);

        this.mses_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_12, 0);

        this.mses_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_13, 0);

        this.manu_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_1, 0);

        this.manu_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_2, 0);

        this.manu_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_3, 0);

        this.manu_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_4, 0);

        this.manu_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_5, 0);

        this.manu_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_6, 0);

        this.manu_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_7, 0);

        this.manu_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_8, 0);

        this.manu_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_9, 0);

        this.manu_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_10, 0);

        this.manu_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_11, 0);

        this.manu_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_12, 0);

        this.manu_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_13, 0);


        resp['data'].filter((caseObj: any) => {

          for (let data of caseObj) {
            caseObj['org_name'] = (data.org_name || '');
          }
          // caseObj['org_name'] = (caseObj.org_name || 0);
          return caseObj
        });




        // this.lagu_nhi = resp['data'].reduce((acc: any, data: any) => acc + data.lagu_nhi, 0);
        // this.parti = resp['data'].reduce((acc: any, data: any) => acc + data.parti, 0);
        this.spinner.hide();
      },
      error: (err: any) => {

        this.spinner.hide();
      }
    });
  }

  doChange(e: any) {

    this.getAOne(e.target.value);
  }

  selectMultiDepartment(e: any) {
    // Retrieve the current selected values from the form control
    const currentSelectedIds = this.form.get('case_petitioner_id')?.value || [];

    // Update selection: Find and add new items that are not already in all_selected_dep
    currentSelectedIds.forEach((id: any) => {
      if (!this.all_selected_dep.some(item => item.department_id === id)) {
        let data = this.getDataO.find(item => item._id === id);
        if (data) {
          this.all_selected_dep.push({ org_name: data.org_name, department_id: data._id });
        }
      }
    });

    // Removal: Find and remove items that are no longer selected
    this.all_selected_dep = this.all_selected_dep.filter(item => {
      return currentSelectedIds.includes(item.department_id);
    });

    // Log final selected departments for verification

  }

  private preloadSelectedDepartments() {
    this.form.value.case_petitioner_id.forEach((id: string) => {
      let d = this.getDataO.find(item => item._id === id);
      if (d) {
        this.all_selected_dep.push({ org_name: d.org_name, department_id: d._id });
      }
    });
  }


}
