import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgGridService {

  constructor() { }

  queryModifier(filterArray: any) {
    let result: any = {};

    filterArray.forEach((filterObject: any) => {
      for (let key in filterObject) {

        if (filterObject.hasOwnProperty(key)) {
          const filterDetails = filterObject[key];
          result[key] = filterDetails.filter;

        }
      }
    });

    return result;
  }


}
