import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-respondent-list',
  templateUrl: './respondent-list.component.html',
  styleUrls: ['./respondent-list.component.css']
})
export class RespondentListComponent implements OnInit, OnDestroy {
  getDataRes: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: BehaviorSubject<any> = new BehaviorSubject<any>(10);
  user: any;
  roledata: any;
  districts: any;
  constructor(
    private router: Router,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService

  ) { }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.respondentData();
  }
  respondentData() {
    this.spinner.show();
    this.MasterS.GetRespondentdata().subscribe((data: any) => {

      this.getDataRes = data.resultData;
      // Paginate the data
      const start = 0; // Start index of the current page
      const length = this.dtOptions.pageLength ?? 10; // Number of items per page with a default value of 10 if pageLength is undefined
      if (this.getDataRes) {
        const currentPageData = this.getDataRes.slice(start, start + length);
        // Update the DataTables trigger with paginated data

        this.dtTrigger.next(currentPageData);
        this.spinner.hide();

      }
    });
  }

  deleteUser(id: number) {

    this.MasterS.deleteRespondent(id).subscribe(
      (data: any) => {
        alert(" Data has been deleted.");
        this.MasterS.GetRespondentdata()

        this.router.navigate(['/dashboard/respondent-list'])
      },

    );
  }

}
