import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resource-document-list',
  templateUrl: './resource-document-list.component.html',
  styleUrls: ['./resource-document-list.component.css']
})
export class ResourceDocumentListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  user: any;
  roledata: any;
  districts: any;
  getData: any;
  constructor(
    private MasterS: MasterService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
      'lengthMenu': [[10, 50, 100, 200, -1], [10, 50, 100, 200, 'All']]
    };
    this.viewdata();
  }

  viewdata() {
    this.MasterS.getResourceDoc().subscribe((data: any) => {

      this.getData = data.resultData;
      this.dtTrigger.next();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.MasterS.deleteResourceDoc(id).subscribe(
      (data: any) => {
        this.MasterS.getResourceDoc()

        // window.location.href = '/resource-document-list';
        this.router.navigate(['/dashboard/resource-document-list']);

      },

    );
  }
}
