<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <div class="lter b-b pt-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <!-- <h3 class="m-n font-medium">Dashboard</h3> -->
                    <h3 class="m-n font-medium"><i class="fa fa-angle-left" aria-hidden="true"></i> EE
                        Details
                    </h3>
                </div>

            </div>
        </div>

        <div class="wrapper-md">
            <div class="panel b-a">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <div> <img src="./assets/img/dashboard.png" alt="." style="width: 100%;"></div> -->
                            <div class="head-title">
                                <h4>EE Information</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bb"></div>
                <div class="panel-body">
                    <form [formGroup]="saveForm" (ngSubmit)="clickFunction()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">SE Name </label>
                                    <!-- <input class="form-control" type="text" placeholder="Enter.." formControlName="se_id"> -->
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="se_id">
                                        <option value=''>
                                            Select
                                        </option>
                                        <option *ngFor="let data of getData" value={{data._id}}>{{data.se_name}}
                                        </option>

                                    </select>
                                    <div *ngIf="f.se_id.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.se_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">EE Name </label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_name">
                                    <div *ngIf="f.ee_name.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_name.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Email / ईमेल </label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_email">
                                    <div *ngIf="f.ee_email.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_email.errors">This field is required</div>
                                    </div>

                                    <div *ngIf="f.ee_email.errors && f.ee_email.errors.pattern && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_email.errors.pattern"><small>Email is not valid</small></div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Phone / फ़ोन </label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_mobile" minlength="10" maxlength="10">
                                    <div *ngIf="f.ee_mobile.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_mobile.errors">This field is required</div>
                                    </div>

                                    <div *ngIf="f.ee_mobile.errors && f.ee_mobile.errors.pattern"
                                        class="text-danger text-left mb-1 errorcontrols">
                                        <div *ngIf="f.ee_mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">District / जिला </label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="ee_district_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataDist" value={{data._id}}>{{data.district}}
                                        </option>

                                    </select>
                                    <div *ngIf="f.ee_district_id.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_district_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Designation / पद </label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_designation">
                                    <div *ngIf="f.ee_designation.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.ee_designation.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Village / ग्राम</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_village">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Address / पता</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_address">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Pincode / पिन कोड</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_pincode">

                                    <div *ngIf="f.ee_pincode.errors && f.ee_pincode.errors.pattern"
                                        class="text-danger text-left mb-1 errorcontrols">
                                        <div *ngIf="f.ee_pincode.errors.pattern">Please, Enter 6 digit Pincode </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Block / ब्लॉक</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_block">
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Division / विभाजन</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="ee_divition">
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="">
                                    <div class="button_group_fotter">
                                        <!-- <button type="submit" class="btn btn-primary">Save</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>