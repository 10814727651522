<div id="cover-spin"></div>
<div class="app app-header-fixed ">
    <header id="header" class="app-header navbar" role="menu">
        <div class="collapse pos-rlt navbar-collapse box-shadow bg-white-only" style="display: flex !important;">
            <div class="nav navbar-nav hidden-xs">
                <img src="./assets/img/logo.png" alt="" height="50" style="margin-inline-end: 20px;">
                <img src="./assets/img/banner.svg" alt="" height="25">
            </div>
            <div class="nav navbar-nav" style="margin-inline: auto;">
            </div>
            <ul class="nav navbar-nav navbar-right" style="display: flex; align-items: center;">
                <li>
                    <a class="btn no-shadow navbar-btn" style="margin: 0; padding: 0; margin-top: 0 !important;">
                        <i class="fa fa-clock-o"></i>
                        <small class="">
                            <span id="date_header_time">
                                {{date | date: 'EE, MMMM d, y, '}}
                            </span>
                            <span id="clock">
                                {{date | date: "HH:mm:ss"}}
                            </span>
                        </small>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="#" data-toggle="dropdown" class="dropdown-toggle get_last_10_notification"
                        data-toggle="dropdown">
                        <i class="fa fa-bell fa-fw g-px-5"></i>
                        <span class="visible-xs-inline">Notifications</span>
                        <span class="badge badge-sm up bg-info pull-right-xs"><span
                                class="noti_count_show">0</span></span>
                    </a>
                    <div class="dropdown-menu w-600">
                        <div class="panel bg-white">
                            <div class="panel-heading b-light bg-light">
                                <a href="#">See all the notifications</a>
                            </div>
                            <div class="list-group noti_content_show">

                            </div>
                        </div>
                    </div>
                </li>
                <li class="dropdown">
                    <a href="#" data-toggle="dropdown" class="dropdown-toggle clear" data-toggle="dropdown">
                        <span class="thumb-sm avatar m-t-n-sm m-b-n-sm m-r-sm">
                            <img src="./assets/img/member-n.png" alt="...">
                            <i class="on md b-white bottom"></i>
                        </span>
                        <span class="hidden-sm hidden-md">Hi
                            <span class="text-uppercase user-profile-name">{{this.username}} </span>
                            <span *ngIf="this.designation">({{this.designation}})</span>
                            <span *ngIf="!this.designation"></span>
                        </span> <b class="caret"></b>
                    </a>
                    <!-- dropdown -->
                    <ul class="dropdown-menu animated fadeInRight w">
                        <!-- <li>
                            <a href>
                                <span class="badge bg-danger pull-right">30%</span>
                                <span>Settings</span>
                            </a>
                        </li> -->
                        <!-- <li>
                            <a ui-sref="app.page.profile" class="user-update-profile">Profile</a>
                        </li> -->
                        <li>
                            <a data-toggle="modal" data-target="#user-profile" aria-hidden="true"
                                ui-sref="app.page.profile" class="user-change-password">Change Password</a>
                        </li>
                        <!-- <li>
                            <a ui-sref="app.docs">
                                <span class="label bg-info pull-right">new</span>
                                Help
                            </a>
                        </li> -->
                        <li class="divider"></li>
                        <li>
                            <a href="#">Logout</a>
                        </li>
                    </ul>
                    <!-- / dropdown -->
                </li>
            </ul>
            <!-- / navbar right -->
        </div>
        <!-- / navbar collapse -->
    </header>
    <!-- / header -->


    <!----------------------------------- User change password model -------------------------->
    <div class="modal fade" id="user-profile" role="dialog">
        <div class="modal-dialog r-2x">
            <div class="modal-content r-2x b-b-3x b-b-theme">
                <div class="modal-header bg-info">
                    <button type="button" class="close" data-dismiss="modal"><i
                            class="fa fa-times text-black m-t-xs"></i></button>
                    <h4 class="modal-title text-white font-bold">Change Password</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="appForm" (ngSubmit)="changeSubmit()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label font-weight-600">Old Password</label>
                                    <input type="password" class="form-control input-sm user-profile-validation"
                                        formControlName="old_pass" placeholder="Enter the current password"
                                        autocomplete="current-password">
                                </div>
                                <div *ngIf="f.old_pass.errors &&  isValidFormSubmitted"
                                    class="text-danger         text-left       mb-1 errorcontrols">
                                    <div *ngIf="f.old_pass.errors.required"><small>Old Password is
                                            required.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label font-weight-600">New Password</label>
                                    <input type="password" class="form-control input-sm user-profile-validation"
                                        formControlName="new_pass" placeholder="Enter the new password"
                                        autocomplete="new-password">
                                </div>
                                <div *ngIf="f.new_pass.errors &&  isValidFormSubmitted"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="f.new_pass.errors.required"><small>New Password is
                                            required.</small>
                                    </div>
                                    <div *ngIf="f.new_pass.errors.pattern"><small>Minimum 8 character password.</small>
                                    </div>
                                    <div *ngIf="f.new_pass.errors.pattern"><small>At least one upper case
                                            (e.g.[A-Z])</small></div>
                                    <div *ngIf="f.new_pass.errors.pattern"><small>At least one lower case
                                            (e.g.[a-z])</small></div>
                                    <div *ngIf="f.new_pass.errors.pattern"><small>At least one digit (e.g [0-9])</small>
                                    </div>
                                    <div *ngIf="f.new_pass.errors.pattern">
                                        <small>
                                            {{'At least one special character (e.g [#?!@$%^&*-]).'}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label font-weight-600">Confirm new Password </label>
                                    <input type="password" class="form-control input-sm user-profile-validation"
                                        formControlName="c_pass" placeholder="Enter the confirm new password"
                                        autocomplete="new-password">
                                </div>
                                <div *ngIf="f.c_pass.errors && f.c_pass.errors.required && isValidFormSubmitted"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="f.c_pass.errors.required"><small>Confirm Password is required.</small>
                                    </div>
                                </div>
                                <div *ngIf="f.c_pass.errors && f.c_pass.errors.mustMatch"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="f.c_pass.errors.mustMatch"><small>New Password and Confirm Password is
                                            not match.</small>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <button type="submit"
                                    class="btn btn-3d m-b-xs w-xs btn-sm btn-success user-profile-submit"
                                    value="submit">
                                    Save</button> &nbsp;
                                <button type="button" class="btn btn-3d m-b-xs  btn-sm btn-dark"
                                    data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-----------------------------------End User change password model --------------------------->