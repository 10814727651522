<div class="dropdown-container">
    <div class="dropdown-trigger form-control" (click)="toggleDropdown()">
        <div class="d-flex justify-content-between">
            {{ displayText }}
            <p *ngIf="selectedItems.length"><i class="fa fa-times-circle-o" aria-hidden="true"
                    (click)="clearSelection()"></i></p>
        </div>
    </div>
    <div class="dropdown-list" *ngIf="isDropdownOpen" (scroll)="onScroll($event)">
        <input type="text" (input)="onSearchInput($event)" placeholder="Search...">
        <div class="loading" *ngIf="majorLoad">Loading...</div>
        <div class="loading" *ngIf="!displayedItems.length && !majorLoad">No data found</div>
        <div *ngFor="let item of displayedItems" (click)="selectItem(item)"
            [class.selected]="selectedItems.includes(item)">
            <input type="checkbox" style="width: max-content;" *ngIf="this.multiple"
                [checked]="selectedItems.includes(item)">
            {{ item[labelField] }}
        </div>
        <div *ngIf="isLoading" class="loading">Loading...</div>
    </div>
</div>