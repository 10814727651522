import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-resource-document-update',
  templateUrl: './resource-document-update.component.html',
  styleUrls: ['./resource-document-update.component.css']
})
export class ResourceDocumentUpdateComponent implements OnInit {
  id: any;
  EditData: any;
  saveForm: any;
  isValidFormSubmitted!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private MasterS: MasterService,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private CaseS: CaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');

    this.MasterS
      .editResourceDoc(this.id)
      .subscribe((data: any) => {


        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
      })

  }
  onforminit(userData: any) {
    this.saveForm = this.fb.group({
      upload: [userData.upload, Validators.required],
      name: [userData.name, Validators.required],
      document_description: [userData.document_description],
    })
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.MasterS
        .updateResourceDoc(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          // window.location.href = '/resource-document-list';
          this.router.navigate(['/dashboard/resource-document-list'])
          // this.saveForm.reset()
        });
    }
  }

  uploadDocument($event: any) {
    let file = $event.target.files;
    if (
      file[0].type == 'image/png' ||
      file[0].type == 'image/jpg' ||
      file[0].type == 'image/jpeg' ||
      file[0].type == 'application/pdf'
    ) {


      if (parseInt(file[0].size) > 5097152) { }
      else {
        const date = 'Wed Feb 20 2019 00:00:00 GMT-0400 (Atlantic Standard Time)';
        const time = '7:00 AM';
        this.CaseS.uploadFile(file[0]).subscribe((data: any) => {

          this.saveForm.get('upload').setValue(data?.body)
          this.saveForm.get('upload').updateValueAndValidity()
        })

      }
    }
    else { }

  }

  get f() {
    return this.saveForm.controls;
  }

}
