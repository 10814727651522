<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <div class="lter b-b pt-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <!-- <h3 class="m-n font-medium">Dashboard</h3> -->
                    <h3 class="m-n font-medium"><i class="fa fa-angle-left" aria-hidden="true"></i> Update
                        Councel </h3>
                </div>

            </div>
        </div>

        <div class="wrapper-md">
            <div class="panel b-a">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <div> <img src="./assets/img/dashboard.png" alt="." style="width: 100%;"></div> -->
                            <div class="head-title">
                                <h4>Councel Information</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bb"></div>
                <div class="panel-body">
                    <form [formGroup]="saveForm" (ngSubmit)="clickFunction()">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Name / नाम <span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="coun_name" [value]="saveForm.get('coun_name')?.value">
                                    <div *ngIf="f.coun_name.errors && isValidFormSubmitted"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="f.coun_name.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Mobile / मोबाइल</label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="coun_mobile" minlength="10" maxlength="10">
                
                                    <div *ngIf="f.coun_mobile.errors && f.coun_mobile.errors.pattern"
                                        class="text-danger text-left mb-1 errorcontrols">
                                        <div *ngIf="f.coun_mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Email / ईमेल <span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="coun_email">
                                </div>

                                <div *ngIf="f.coun_email.errors && f.coun_email.errors.pattern && isValidFormSubmitted"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="f.coun_email.errors.pattern"><small>Email is not valid</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="">

                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>