import { Component, OnInit } from '@angular/core';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-permission-list',
  templateUrl: './role-permission-list.component.html',
  styleUrls: ['./role-permission-list.component.css']
})
export class RolePermissionListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  getData: any;
  constructor(
    private RoleS: RolePermissionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
    };
    this.viewdata();
  }

  viewdata() {
    this.RoleS.GetRolesData().subscribe((data: any) => {

      this.getData = data.resultData;
      this.dtTrigger.next();
    });
  }

  deleteUser(id: number) {
    alert(" Data has been deleted.")
    this.RoleS.deleteRoles(id).subscribe(
      (data: any) => {
        this.RoleS.GetRolesData()

        // this.toast.showSuccess(
        //   'Congratulation!, Data has been deleted.'
        // );
        // window.location.href = '/role-permission-list';
        this.router.navigate(['/dascboard/role-permission-list']);
      },

    );
  }
}
