
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { ToasterService } from 'src/app/services/toaster.service';

export interface Beneficiary {
  _id: string;
  case_no: string;
}


@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.css']
})
export class CaseDetailsComponent implements OnInit {
  @ViewChild('taskModal') taskModal: any;
  @ViewChild('docModal') docModal: any;
  @ViewChild('TaskStatus') TaskStatus: any;
  myControl = new FormControl('');
  myControlActionType = new FormControl('');
  options: string[] = [];
  optionsActionType: string[] = [];


  filteredOptions: Observable<Beneficiary[]> | undefined;


  isShow = true;
  casedata: any;
  getCaseNoData: any;
  getDataCS: any;
  isValidFormSubmitted!: boolean;
  saveForm: any;
  caseid: any;
  isValidFormSubmittedTask!: boolean;
  saveFormTask: any;
  getDataDist: any;
  getDataExpectedAction: any;
  largeModal: any;
  detailsdata: any;
  sourceValue: any;
  showData: boolean = true;
  saveFormDoc: any;
  username: any;
  router: any;
  Udata: any;
  getDataUser: any;
  saveFormTaskStatus: any;
  isValidFormSubmittedTaskStatus!: boolean;
  id: any;
  EditDataTaskStatus: any;
  largeModal2: any;
  getDataTaskType: any;
  ids: any;
  refcasedata: any;
  beneficiarylist: Beneficiary[] = [];
  isValidFormSubmittedAttach!: boolean;
  tasktypedatas: any;
  tasktypedata: any;


  constructor(private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private CaseS: CaseService,
    private _Activatedroute: ActivatedRoute,
    private AuthS: AuthService,
    private routers: Router,
    private spinner: NgxSpinnerService,
  ) {
    this.caseid = this._Activatedroute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.spinner.show();
    this.onforminitTaskStatus()
    this.username = localStorage.getItem('id')
    this.Udata = localStorage.getItem('userdata');
    this.onforminitTask();
    this.onforminitDoc();
    this.init();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(beneficiarylist => (beneficiarylist ? this._filter(beneficiarylist) : this.beneficiarylist.slice())),
    );
  }

  init() {
    const caseData$ = this.MasterS.getDataCaseNoById(this.caseid);
    const caseNoList$ = this.MasterS.GetCaseNo();
    const statusData$ = this.MasterS.GetStatusdata();
    const districtData$ = this.MasterS.GetDistrictata();
    const expectedAction$ = this.MasterS.getExpectedAction();
    const userData$ = this.AuthS.getUser();
    const taskTypeData$ = this.MasterS.getTaskType();

    forkJoin([
      caseData$,
      caseNoList$,
      statusData$,
      districtData$,
      expectedAction$,
      userData$,
      taskTypeData$
    ]).subscribe(([caseDataResponse, caseNoListResponse, statusDataResponse, districtDataResponse, expectedActionResponse, userDataResponse, taskTypeDataResponse]: any) => {
      this.casedata = caseDataResponse.resultData;
      this.tasktypedata = caseDataResponse.taskData;
      this.caseid = this.casedata[0]?._id;
      this.getCaseNoData = caseNoListResponse.resultData;
      this.getDataCS = statusDataResponse.resultData;
      this.getDataDist = districtDataResponse.resultData;
      this.getDataExpectedAction = expectedActionResponse.resultData;
      this.getDataUser = userDataResponse.resultData;
      this.getDataTaskType = taskTypeDataResponse.resultData;
      this.onforminit();
      this.spinner.hide();
    });
  }

  private _filter(value: string): Beneficiary[] {
    const filterValue = value.toLowerCase();
    return this.getCaseNoData.filter((option: any) => option.case_no.toLowerCase().includes(filterValue))
  }

  viewdataUser() {
    this.AuthS.getUser().subscribe((data: any) => {
      this.getDataUser = data.resultData;
    });
  }

  getCaseNolist() {
    this.MasterS.GetCaseNo().subscribe((data: any) => {
      this.getCaseNoData = data.resultData;
    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getCaseNo(e: any) {
    let case_no = e;
    this.sourceValue = case_no;
    if (this.sourceValue === 'VALUEA') this.showData = true;
    else this.showData = false;

    this.MasterS
      .getDataCaseNoById({
        case_no: e,
      })
      .subscribe((data: any) => {
        this.casedata = data;
        this.tasktypedata = data.taskData
        this.caseid = this.casedata?.resultData[0]?._id
        this.onforminit()
      });
  }

  statusData() {
    this.MasterS.GetStatusdata().subscribe((data: any) => {
      this.getDataCS = data.resultData;
    });
  }

  taskTypeData() {
    this.MasterS.getTaskType().subscribe((data: any) => {
      this.getDataTaskType = data.resultData;
    });
  }

  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {
      this.getDataDist = data.resultData;
    });
  }

  viewdataExpectedAction() {
    this.MasterS.getExpectedAction().subscribe((data: any) => {
      this.getDataExpectedAction = data.resultData;
    });
  }

  onforminit() {
    this.saveForm = this.fb.group({
      hearing_date: ["", Validators.required],
      case_id: [this.caseid],
      next_hearing_date: [""],
      court_order_date: [""],
      // next_hearing_court_order_date:[''],
      hearing_details: [""],
      // hearing_document:[''],
      expected_action_type: ["", Validators.required],
      compliance_action: [""],
      compliance_action_start_date: [""],
      compliance_action_end_date: [""],
      land_mark_judgement: [""],
      case_status_id: ["", Validators.required],
      // land_mark_document:[''],
      club_case: [""],
      nextDate: [""],
      previousDate: [""],
      complianceDate: [""],
      disposedDate: [""],
      // case_task:['']
    });
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {
      this.toast.showError(
        'Required fields are missing.'
      );
      this.isValidFormSubmitted = true;
    } else {
      this.CaseS
        .saveCaseDetails(this.saveForm.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.routers.navigate(['/dashboard/case-details']);
        });
    }
  }

  get f() {
    return this.saveForm.controls;
  }

  get attach() {
    return this.saveFormDoc.controls;
  }

  onforminitTask() {
    this.saveFormTask = this.fb.group({
      task_case_id: [this.caseid],
      case_task_name: ['', Validators.required],
      case_task_type: ['', Validators.required],
      case_task_subject: [''],
      case_task_owner: ['', Validators.required],
      case_task_duedate: [''],
    });
  }

  clickFunctionTask() {
    this.isValidFormSubmittedTask = false;
    if (this.saveFormTask.invalid) {
      this.isValidFormSubmittedTask = true;
    } else {
      this.MasterS
        .saveCaseTaskData(this.saveFormTask.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          // window.location.href = '/case-details';
          this.saveFormTask.reset();
          this.taskModal.nativeElement.click();
        });
    }
  }

  get task() {
    return this.saveFormTask.controls;
  }

  hearing(e: any) {
    this.caseid = $(this).attr("id");
    alert(this.caseid)
  }

  showModal(id: number) {
    this.caseid = id;
    this.largeModal.show();
  }

  onforminitDoc() {
    this.saveFormDoc = this.fb.group({
      doc_case_id: [this.caseid],
      case_doc_type: ['', Validators.required],
      case_doc_name: ['', Validators.required],
      // case_doc_size: [''],
      case_doc_attached_by: [this.username],
      case_document: [''],
    });
  }

  clickFunctionDoc() {
    this.isValidFormSubmittedAttach = false;
    if (this.saveFormDoc.invalid) {
      this.isValidFormSubmittedAttach = true;
    } else {
      this.MasterS
        .saveCaseDoc(this.saveFormDoc.value)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          // window.location.href = '/case-details';
          this.saveFormDoc.reset();
          this.docModal.nativeElement.click();
        });
    }
  }

  uploadDocument($event: any) {
    let file = $event.target.files;
    if (
      file[0].type == 'image/png' ||
      file[0].type == 'image/jpg' ||
      file[0].type == 'image/jpeg' ||
      file[0].type == 'application/pdf' ||
      file[0].type == 'application/xlsx'
    ) {
      if (parseInt(file[0].size) <= 5097152) {
        this.CaseS.uploadFile(file[0]).subscribe((data: any) => {
          this.saveFormDoc.get('case_document')?.setValue(data?.body)
          this.saveFormDoc.get('case_document')?.updateValueAndValidity()
        })
      }
    }
  }

  showModalTask(ids: number) {
    this.id = ids;
  }

  onforminitTaskStatus() {
    this.saveFormTaskStatus = this.fb.group({
      status: [''],
    });
  }

  clickFunctionStatusOfTask() {
    this.isValidFormSubmittedTaskStatus = false;
    if (this.saveFormTaskStatus.invalid) {
      this.isValidFormSubmittedTaskStatus = true;
    } else {
      this.MasterS
        .updateCaseTask(this.saveFormTaskStatus.value, this.id)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been Updated.'
          );
          this.router.navigate(['/dashboard/case-details'])
          this.saveFormTaskStatus.reset()
          this.TaskStatus.nativeElement.click();
        });
    }
  }

  GetCaseNoByRefCaseId(e: any) {
    this.MasterS
      .GetCaseNoByRefCaseId({
        ref_case_no: e.target.value,
      })
      .subscribe((data: any) => {
        this.refcasedata = data.resultData;
      });
  }
}
