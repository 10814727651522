<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>

<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="lter b-b py-5 ps-4 bg-white box-shadow">
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <h3 class="m-n font-medium"> Case List View {{ datapetitioner }}</h3>
        </div>
        <div class="col-sm-4 text-right poppins hidden-xs">
          <button (click)="getSLPCaseData()" class="btn btn-sm text-10 btn-addNew m-r-xs ">
            SLP </button>
          <button (click)="getcase_contemptCaseData()" class="btn btn-sm text-10 btn-addNew m-r-xs ">
            Contempt</button>
          <button (click)="getAllcase()" class="btn btn-sm text-10 btn-addNew m-r-xs ">
            ALl Case Data </button>
          <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->

          <!-- </button>
                <button data-toggle="modal" data-target="#basicModal" type="submit" class="btn btn-sm text-10 btn-addNew m-r-xs "> Import</button>-->
        </div>
        <div class="col-sm-4 text-right poppins hidden-xs">
          <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
          <a routerLink="/dashboard/add-case" routerLinkActive="active"
            class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" *ngIf="roledata.caseCreate"><i
              class="fa fa-plus"></i> Add Case </a>
          <!-- </button>
                          <button data-toggle="modal" data-target="#basicModal" type="submit" class="btn btn-sm text-10 btn-addNew m-r-xs "> Import</button>-->
        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row mw">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs" style="overflow: auto;">
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th class="text-center">#</th>
                      <th style="width: 4rem;">Court</th>
                      <th>Case</th>
                      <th>Section/Department</th>
                      <!-- <th>Department</th> -->
                      <!-- <th>Notice</th> -->
                      <th>Case status</th>
                      <!-- <th>Respondent</th> -->
                      <!-- <th>Counsel</th> -->
                      <!-- <th>Representing Officer</th>
                        <th>Organization</th> -->
                      <th>Affidavit Status</th>
                      <th>Case SLP</th>

                      <th>Case Contempt</th>
                      <th> HOD Case</th>

                      <th>PIL</th>
                      <th>Principal Secretary as a respondent</th>
                      <th>Chief Secretary as a respondent</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" *ngFor="let data of getData11; let i= index;">
                      <td class="text-center">{{ [i+1] }}</td>
                      <td style="width: 4rem !important;"><span
                          class="font-bold text-theme">{{data?.case_court_id?.court_name}} </span></td>

                      <td>
                        <ul class="list-unstyled text-xs">

                          <li><span class="font-bold text-theme">{{data?.case_no}}</span> </li>
                          <!-- <li><span class="font-bold text-theme">Writ Status : </span> {{data?.writ_status}} </li> -->
                          <!-- <li><span class="font-bold text-theme">Case Type: </span>
                                                      {{data?.case_type_id?.case_type_name}} </li> -->
                          <li><span class="font-bold text-theme">Case Year: </span> {{data?.case_year}}</li>
                          <li><span class="font-bold text-theme">District: </span> {{data?.case_district_id?.district}}
                          </li>
                          <li><span class="font-bold text-theme">Next Hearing: </span> {{data?.next_hearing_date}}</li>

                          <li><span class="font-bold text-theme">Notice No. : </span> {{data?.case_notice_no}}</li>
                        </ul>
                      </td>
                      <td>
                        <ul class="list-unstyled text-xs">
                          <li><span class="font-bold text-theme">Govt. Section:
                            </span>{{data?.case_section_id?.section_name}}</li>
                          <li><span class="font-bold text-theme">H.O. Section: </span> {{data?.case_ho_id?.ho_name}}
                          </li>
                          <li><span class="font-bold text-theme">Deptt: </span>{{data?.case_orgnaization_id?.org_name
                            }}</li>

                          <li><span class="font-bold text-theme">CE: </span>{{data?.case_sun_org_id?.sub_org_name }}
                          </li>
                          <!-- <li><span class="font-bold text-theme">SE: </span> {{data?.seData[0]?.se_name}}</li> -->
                          <li><span class="font-bold text-theme">SE: </span> {{data?.case_se_id?.se_name}}</li>
                          <li><span class="font-bold text-theme">Representing/EE: </span>
                            <!-- {{data?.eeData[0]?.ee_name}} -->
                            <p *ngFor="let index of data?.case_ee_id" style="margin: 0 0 -2px;">
                              {{ index?.ee_name }}
                            </p>
                          </li>

                          <!-- <li>{{data?.eeData[0]?.ee_designation}}</li> -->

                        </ul>
                      </td>
                      <!-- <td>
                          <ul class="list-unstyled text-xs">
                            <li><span class="font-bold text-theme">Organization: </span>{{data?.orgData[0]?.org_name}}</li>
                            <li><span class="font-bold text-theme">Sub Organization: </span>{{data?.suborgData[0]?.sub_org_name}}</li> 
                          </ul>
                        </td> -->
                      <!-- <td><span class="font-bold text-theme">{{data?.case_notice_no}}</span></td> -->
                      <td>

                        <ul class="list-unstyled text-xs">
                          <li><span class="font-bold text-theme">Case Status : </span>
                            <p>{{ data?.case_status_id?.case_status_name
                              }}</p>
                          </li>
                        </ul>
                        <!-- <ul>
                            <li *ngFor="let index of data?.petitionerCollection" >
                              {{ index.name }}
                            </li>
                          </ul> -->
                      </td>
                      <!-- <td><span class="font-bold text-theme">Respondent: </span>{{data?.case_respondent_id}}</td> -->
                      <!-- <td>
                          <ul class="list-unstyled text-xs">
                            <li><span class="font-bold text-theme">Pet. Consel: </span> </li>
                           
                            <li><span class="font-bold text-theme">Counsel: </span>{{data?.case_counsel_id}}</li>
                          </ul>
                        </td> -->
                      <!-- <td>
                          <ul class="list-unstyled text-xs">
                            <li><span class="font-bold text-theme">{{data?.case_ee_id}}</span></li>
                            <li>{{data?.eeData[0]?.ee_designation}}</li>
                          </ul>
                        </td> -->
                      <!-- <td>
                          <ul class="list-unstyled text-xs">
                            <li><span class="font-bold text-theme">CE: </span>{{data?.ceData[0]?.ce_name}}</li>
                            <li><span class="font-bold text-theme">SE: </span> {{data?.seData[0]?.se_name}}</li>
                            <li><span class="font-bold text-theme">EE: </span>{{data?.eeData[0]?.ee_name}}</li>
                          </ul>
                        </td> -->

                      <td lass="col-md-2">
                        <div *ngIf="this.data.case_affidavit_field ==='Yes' ">
                          <ul class="list-unstyled text-xs">

                            <li><span class="font-bold text-theme">Filing Date:
                              </span>{{data?.case_affidavit_filing_date}}</li>
                            <li><span class="font-bold text-theme">Due Date: </span>{{data?.case_affidavit_due_date}}
                            </li>
                            <li class="Pending-Apprroval">Filed</li>
                          </ul>
                        </div>
                        <div *ngIf="!this.data.case_affidavit_field">
                          <ul class="list-unstyled text-xs">

                            <li><span class="font-bold text-theme">Due Date: </span>{{data?.case_affidavit_due_date}}
                            </li>
                            <li class="Pending-Apprroval pending_Badge">Not Filed</li>
                          </ul>
                        </div>

                      <td>{{data?.case_slp}}</td>

                      <td>{{data?.case_contempt}}</td>
                      <td>{{data?.hod}}</td>
                      <td>{{data?.PIL?data?.PIL:'No'}}</td>

                      <td>{{data?.PSR?data?.PSR:'No'}}</td>
                      <td>{{data?.CSR?data?.CSR:'No'}}</td>
                      <td>
                        <span class="font-bold text-theme">Name : </span>
                        <!-- <p>{{data?.createdBy[0]?.username}}</p>
                              <span class="font-bold text-theme">Designation : </span>
                              <p>{{data?.createdBy[0]?.designation}}</p>-->
                      </td>
                      <td>
                        <span class="font-bold text-theme">Name : </span>
                        <!--  <p>{{data?.updatedBy[0]?.username}}</p>
                          <span class="font-bold text-theme">Designation : </span>
                          <p>{{data?.updatedBy[0]?.designation}}</p>-->
                      </td>
                      <td class="text-center">
                        <div class="btn-group dropdown">
                          <button class="btn btn-default w-xxs btn-sm r-2x" data-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-cog g-px-5  text-info text-sm"></i> <span class=""><i
                                class="fa fa-caret-down"></i></span></button>
                          <ul class="dropdown-menu">
                            <li *ngIf="roledata.caseEdit"><a routerLinkActive="active"
                                routerLink="/dashboard/case-update/{{data?._id}}"><i class="fa fa-edit"></i> Edit </a>
                            </li>
                            <li *ngIf="roledata.caseDelete"><a (click)="deleteUser(data._id)"><i
                                  class="fa fa-trash-o"></i> Delete </a></li>
                            <!-- <li data-toggle="modal" data-target="#hearingModal" type="submit" ><a class="add-model" data-id="{{data?._id}}"   (click)="showModal(data?._id)"><i class="fa fa-calendar" ></i> Next Hearing  </a></li> -->
                          </ul>
                        </div>
                      </td>
                    </tr>


                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>

<!-- ..........................................Import case data............................ -->

<div class="modal fade" id="basicModal" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
  #noteModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel">Import Case Data</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="input_grp form-group">
              <input id="toggleElement" type="checkbox" name="toggle" onchange="toggleStatus()" />
              <label for="">प्रारूप <span class="text-danger">*</span></label>
              <div id="elements"><input type="file" (change)="importCases($event)" name="name" /> <br /></div>
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="input_grp form-group">
                <input id="toggleEl" type="checkbox" name="toggle" onchange="Status()" />
                <label for="">प्रारूप 2<span class="text-danger">*</span></label>
                <div id="elementsToOperateOn"><input type="file" name="name"  /> <br /></div>
              </div>
            </div> -->
          <div class="col-md-6">
            <a href="https://lcms.evalue8.info/LCMS_Sample_Sheet_id.xlsx" target="_blank" download>
              <p class="text-css"> Sample sheet <i class="fa fa-download" aria-hidden="true"></i></p>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="modal-footer">
              <!-- <button type="submit" class="btn btn-primary">Save</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ..........................................Next Hearing POPUP MODAL............................ -->

<div class="modal fade" id="hearingModal" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
  #hearingModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel">Next Hearing</h3>
      </div>
      <div class="modal-body">
        <!-- <form [formGroup]="saveForm" (ngSubmit)="clickFunction()" (onclick)="hearing('{{data?._id}}')" > -->
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="input_grp form-group">
                <label for="">Next Hearing Date / अगली सुनवाई की तिथि {{this.EditData?.next_hearing_date}}</label>
                <input class="form-control" type="date" formControlName="next_hearing_date">

              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>