import {
  Component, OnInit, ViewChild
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToasterService } from 'src/app/services/toaster.service';
import { CaseService } from 'src/app/services/case.service';
import * as XLSX from 'xlsx';
import { Subject, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
// addCaseImport
@Component({
  selector: "app-case-list",
  templateUrl: "./case-list.component.html",
  styleUrls: ["./case-list.component.css"],
})
export class CaseListComponent implements OnInit {
  getData: any;
  title = "datatables";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  @ViewChild("noteModal") noteModal: any;
  @ViewChild("hearingModal") hearingModal: any;
  id: any;
  EditData: any;
  saveForm: any;
  isValidFormSubmittedCont!: boolean;
  ids: any;
  largeModal: any;
  petiById: any = [];
  j: any;
  datapetitioner: any;
  user: any;
  roledata: any;
  districts: any;
  getData11: any = [];
  userID: any
  constructor(
    private toast: ToasterService,
    private CaseS: CaseService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.ids = this._Activatedroute.snapshot.paramMap.get("id");
    this.user = localStorage.getItem("userdata");
    this.userID = localStorage.getItem("id");
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district;

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: "lfrtip",
      lengthMenu: [
        [10, 50, 100, 200, -1],
        [10, 50, 100, 200, "All"],
      ],
    };
    this.spinner.show();
    this.CaseS.GetCaseDatas(this.userID).subscribe((data: any) => {
      this.getData = data.resultData;
      let newData: any = [];
      this.getData.map((item: any, index: any) => {
        newData.push(item);
      });
      this.getData11 = newData;
      this.dtTrigger.next();
    });
    this.CaseS.getALLCase(this.userID).subscribe((data: any) => {
      this.getData = data.resultData;
      let newData: any = [];
      this.getData.map((item: any, index: any) => {
        newData.push(item);
      });
      this.getData11 = newData;
      this.rerender();
      this.spinner.hide();
    });
  }

  deleteUser(id: number) {
    this.CaseS.deleteCase(id).subscribe((data: any) => {
      this.CaseS.GetCaseData(this.userID);
      this.toast.showSuccess(
        'Congratulation!, Case has been deleted.'
      );
    });
  }

  viewdata() {
    this.CaseS.GetCaseData(this.userID).subscribe((data: any) => {
      this.getData = data.resultData;
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 10,
        ordering: true,
        searching: true,
        processing: true,
        dom: "lfrtip",
        lengthMenu: [
          [10, 50, 100, 200, -1],
          [10, 50, 100, 200, "All"],
        ],
      };
      this.dtTrigger.next(this.getData11);
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
    });
  }

  getSLPCaseData() {
    this.spinner.show();
    this.CaseS.getSLPCaseData().subscribe((data: any) => {
      this.getData = data.resultData;
      let newData: any = [];
      this.getData.map((item: any, index: any) => {
        if (this.districts.includes(item.case_district_id?._id)) {
          newData.push(item);
        }
      });
      this.getData11 = newData;
      this.rerender();
      this.spinner.hide();
    });
  }
  getcase_contemptCaseData() {
    this.spinner.show();
    this.CaseS.getcase_contemptCaseData().subscribe((data: any) => {
      this.getData = data.resultData;
      let newData: any = [];
      this.getData.map((item: any, index: any) => {
        if (this.districts.includes(item.case_district_id?._id)) {
          newData.push(item);
        }
      });
      this.getData11 = newData;
      this.rerender();
      this.spinner.hide();
    });
  }

  getAllcase() {
    this.spinner.show();
    this.CaseS.getALLCase(this.userID).subscribe((data: any) => {
      this.getData = data.resultData;
      let newData: any = [];
      this.getData.map((item: any, index: any) => {
        if (this.districts.includes(item.case_district_id?._id)) {
          newData.push(item);
        }
      });
      this.getData11 = newData;
      this.rerender();
      this.spinner.hide();
    });
  }

  importCases(event: any) {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let succcess = true;
      data.map((item) => {
        this.CaseS.importCase(item).subscribe((data: any) => {
          if (data.statuscode === 200) {
            this.toast.showSuccess("Congratulation!, Data has been import.");
            this.router.navigate(['/dashboard'])
          } else if (data.statuscode === 201) {
            this.toast.showError("Oops! Case no is all ready exist.");
          }
          else {
            this.toast.showError("Oops! Data has been not import.");
          }
          succcess = true;
        });
      });
    };
  }

  hearing(e: any) {
    this.id = $(this).attr("id");
    alert(this.id);
  }

  showModal(id: number) {
    this.id = id;
    this.largeModal.show();
  }
}