import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { AuthService } from 'src/app/services/auth.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { Router } from '@angular/router';
@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  saveForm: any;
  getDataS: any;
  getDataOrg: any;
  getDataEE: any;
  getDataDist: any;
  getDataDept: any;
  isValidFormSubmitted!: boolean;
  isPasswordSame: any;
  username: any;
  router: any;
  Udata: any;
  district: any;
  getDataRoles: any;
  subOrgDataByOrg: any;
  seDataBySubOrg: any;
  EeDataBySe: any;
  getDataO: any;
  roleData: any;
  constructor(
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private routers: Router,
    private MasterS: MasterService,
    private AuthS: AuthService,
    private RoleS: RolePermissionService
  ) { }

  ngOnInit(): void {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
    this.username = localStorage.getItem("username")
      ? localStorage.getItem("username")
      : this.routers.navigate(["/"]);
    this.Udata = localStorage.getItem("userdata");

    this.secData();
    this.OrgData();
    this.viewdataEE();
    this.districtData();
    this.departmentData();
    this.onforminit();
    this.viewdataRoles();
  }

  viewdataRoles() {
    this.RoleS.GetRolesData().subscribe((data: any) => {

      this.getDataRoles = data.resultData;
    });
  }

  onforminit() {
    this.saveForm = this.fb.group(
      {
        username: ["", Validators.required],
        designation: [""],
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
          ],
        ],
        mobile: [
          "",
          [Validators.required, Validators.pattern("^[0-9][0-9]{9}$")],
        ],
        district: ["", Validators.required],
        circle_name: [""],
        // ee_name: ['',Validators.required],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            ),
          ],
        ],
        confirm_password: ["", [Validators.required]],
        office_name: [""],
        created_by: [this.username],
        role: ["", Validators.required],

        case_se_id: [""],
        case_sun_org_id: [""],
        case_ee_id: [""],
        case_orgnaization_id: [""],
      },
      { validators: this.checkPassword("password", "confirm_password") }
    );
  }
  checkPassword(controlName: any, matchingControlName: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
        this.isPasswordSame = matchingControl.status == "VALID" ? true : false;
      } else {
        matchingControl.setErrors(null);
        this.isPasswordSame = matchingControl.status == "VALID" ? true : false;
      }
    };
  }

  clickFunction() {

    this.isValidFormSubmitted = false;

    let userData;
    if (this.roleData?.role_name === "EE") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        password: this.saveForm.value.password,
        confirm_password: this.saveForm.value.confirm_password,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_se_id: this.saveForm.value.case_se_id,
        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_ee_id: this.saveForm.value.case_ee_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "Department") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        password: this.saveForm.value.password,
        confirm_password: this.saveForm.value.confirm_password,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "Organization") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        password: this.saveForm.value.password,
        confirm_password: this.saveForm.value.confirm_password,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "SE") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        password: this.saveForm.value.password,
        confirm_password: this.saveForm.value.confirm_password,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_se_id: this.saveForm.value.case_se_id,
        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    }
    else {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        password: this.saveForm.value.password,
        confirm_password: this.saveForm.value.confirm_password,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,
      };
    }
    if (this.saveForm.invalid) {

      this.toast.showError("Required fields are missing.");
      this.isValidFormSubmitted = true;
    } else {

      this.AuthS.saveUser(userData)
        .subscribe((data: any) => {
          if (data.statuscode === 200) {

            this.toast.showSuccess("Congratulation!, Data has been submited.");
            // window.location.href = "/list-user";
            this.routers.navigate(['/dashboard/list-user']);
          } else {
            this.toast.showError("Oops! Email is all ready exist!");
          }
        });
    }
  }



  get f() {
    return this.saveForm.controls;
  }
  secData() {
    this.MasterS.GetSecdata().subscribe((data: any) => {

      this.getDataS = data.resultData;
    });
  }

  OrgData() {
    this.MasterS.GetSubOrgdata().subscribe((data: any) => {

      this.getDataOrg = data.resultData;
    });
  }

  viewdataEE() {
    this.MasterS.GetEEdata().subscribe((data: any) => {

      this.getDataEE = data.resultData;
    });
  }

  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {

      this.getDataDist = data.resultData;
    });
  }

  departmentData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataDept = data.resultData;
    });
  }

  filter(data: any) {
    this.district = [];
    // let petitionerData= this.saveForm.value.district
    
    // for(let i of petitionerData){
    
    //  this.MasterS
    //    .GetDistrictata()
    //    .subscribe((data: any) => {
    
    //      this.district.push(data.resultData[0]);
    //      

    //    });
    //  }
  }

  getSubOrgByOrgd(e: any) {


    this.MasterS.getSubOrgByOrg({
      case_sun_org_id: e.target.value,
    }).subscribe((data: any) => {

      this.subOrgDataByOrg = data.result;
    });
    // this.saveFormMain.controls.case_se_id.reset();
    // this.saveFormMain.controls.case_ee_id.reset();
  }

  getSeBySubOrgD(e: any) {


    this.MasterS.getSeBySubOrgData({
      case_se_id: e.target.value,
    }).subscribe((data: any) => {
      this.seDataBySubOrg = data.result;
    });

    // this.saveFormMain.controls.case_ee_id.reset();
  }

  getEeBySeD(e: any) {


    this.MasterS.getEeBySeData({
      case_ee_id: e.target.value,
    }).subscribe((data: any) => {
      this.EeDataBySe = data.result;
    });
  }
  checkRole() {
    this.getDataRoles.map((item: any) => {
      if (item._id === this.saveForm.value.role) {
        this.roleData = item

        if (this.roleData?.role_name === 'EE') {
          this.saveForm.get("case_orgnaization_id")?.setValidators(Validators.required);
          this.saveForm.get("case_sun_org_id")?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.setValidators(Validators.required);
          this.saveForm.get("case_ee_id")?.setValidators(Validators.required);
          this.saveForm?.updateValueAndValidity()

        }
        else if (this.roleData?.role_name === 'Department') {
          this.saveForm.get("case_orgnaization_id")?.setValidators(Validators.required);
          this.saveForm.get("case_sun_org_id")?.clearValidators();
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        }
        else if (this.roleData?.role_name === 'Organization') {
          this.saveForm.get("case_orgnaization_id")?.setValidators(Validators.required);
          this.saveForm.get("case_sun_org_id")?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        }
        else if (this.roleData?.role_name === "SE") {
          this.saveForm
            .get("case_orgnaization_id")
            ?.setValidators(Validators.required);
          this.saveForm
            .get("case_sun_org_id")
            ?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.setValidators(Validators.required);
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        } else {
          this.saveForm
            .get("case_orgnaization_id")
            ?.clearValidators();
          this.saveForm.get("case_sun_org_id")?.clearValidators();
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        }
      }
    })
  }
}
