<div class="searchbox">
    <div class="row">

        <div class="col-md-4 expoarc-csv" *ngIf="isExpoartCsv && (userList?.length!==0)">
            <select *ngIf="isThemeChange" class="form-control hidden-xs" (change)="doChangeTheme($event)" name="" id="">
                <option value="ag-theme-balham">Balham</option>
                <option value="ag-theme-material">Material</option>
                <option value="ag-theme-quartz">Quartz</option>
                <option value="ag-theme-custom-angular">Test Case</option>
                <option value="ag-theme-alpine">Alpine</option>
            </select>
        </div>
        <div class="col-md-4" style="padding-bottom: 12px;" *ngIf="isSearch">
            <div class="input-group">
                <input #input [id]="ids" (input)="onSearch($event)" type="search" class="form-control input-sm"
                    placeholder="Filter any column..." />
                <span class="input-group-btn">
                    <button style="background-color: #886838" type="submit" class="btn bg-blue btn-default btn-sm">
                        <i style="color: #ffffff" class="fa fa-search"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>

<ag-grid-angular style="width: 100%;" remoteGridBinding [class]="themeClass" [columnDefs]="colDefs"
    [defaultColDef]="defaultColDef" [pagination]="true" [rowSelection]="'multiple'" [cacheBlockSize]="cacheBlockSize"
    [paginationPageSize]="paginationPageSize" (remoteGridReady)="onGridReady($event)" [domLayout]="domLayout"
    [icons]="filterIcons" [gridOptions]="gridOptions" [alwaysShowHorizontalScroll]="true"
    [alwaysShowVerticalScroll]="true" [groupDisplayType]="groupDisplayType" [autoGroupColumnDef]="autoGroupColumnDef"
    [rowGroupPanelShow]="rowGroupPanelShow" [remoteGridBinding]="remoteGridBinding" [rowModelType]="'infinite'"
    [maxConcurrentDatasourceRequests]="1" [blockLoadDebounceMillis]="1000" [debug]="false"
    [infiniteInitialRowCount]="infiniteInitialRowCount" [animateRows]="true">
</ag-grid-angular>