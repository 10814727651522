import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orgnaisation-add',
  templateUrl: './orgnaisation-add.component.html',
  styleUrls: ['./orgnaisation-add.component.css']
})
export class OrgnaisationAddComponent implements OnInit {
  saveForm: any;
  isValidFormSubmitted!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private ho: MasterService,
    private toast: ToasterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.onforminit();
  }

  onforminit() {
    this.saveForm = this.fb.group({
      org_name: ['', Validators.required],
    });
  }
  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {

      this.isValidFormSubmitted = true;
    } else {

      this.ho
        .saveOrganization(this.saveForm.value)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this.router.navigate(['/dashboard/orgnaisation-list']);
        });
    }
  }

  get f() {
    return this.saveForm.controls;
  }

}
