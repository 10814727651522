import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { CaseService } from 'src/app/services/case.service';
import { Form, UntypedFormBuilder, UntypedFormControl, NgForm, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-petitioner-update',
  templateUrl: './petitioner-update.component.html',
  styleUrls: ['./petitioner-update.component.css']
})
export class PetitionerUpdateComponent implements OnInit {
  saveForm!: FormGroup;
  isValidFormSubmitted!: boolean;
  getDataDist: any;
  id: any;
  EditData: any;

  constructor(
    private MasterS: MasterService,
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private spinner: NgxSpinnerService
  ) { }

  get f() {
    return this.saveForm.controls;
  }
  ngOnInit(): void {
    this.spinner.show()
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.saveForm = this.fb.group({
      pet_employee: [''],
      name: [''],
      designation: [''],
      district_id: [''],
      // block: [userData.block],
      // village: [userData.village],
      // pincode: [userData.pincodes, [Validators.pattern('^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$')]],
      // address: [userData.address],
      // divition: [userData.divition],
    });
    this.MasterS
      .editPetitioner(this.id)
      .subscribe((data: any) => {
        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)
        this.spinner.hide();
      })
    this.districtData();
  }
  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {
      this.getDataDist = data.resultData;
    });
  }
  onforminit(userData: any) {
    this.saveForm.patchValue(userData)
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      let form = this.saveForm.value;
      if (form.pet_employee == "Employee" && !form.designation) {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill designation of the petitioner.'
        );
        return;
      }
      if (form.pet_employee == "Non Employee" && !form.name) {
        this.isValidFormSubmitted = true;
        this.toast.showError(
          'Please fill name of the petitioner.'
        );
        return;
      }
      this.MasterS
        .updatePetitioner(this.saveForm.value, this.id)
        .subscribe((data: any) => {
          this.toast.showSuccess(
            'Congratulation!, Data has been submited.'
          );
          this._location.back();

          // this.router.navigate(['/dashboard/petitioner-list']);
        });
    }
  }
}
