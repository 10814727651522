import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-role-permission-update',
  templateUrl: './role-permission-update.component.html',
  styleUrls: ['./role-permission-update.component.css']
})
export class RolePermissionUpdateComponent implements OnInit {
  id: any;
  EditData: any;
  isValidFormSubmitted!: boolean;
  saveForm: FormGroup;
  isDisabled!: boolean;

  constructor(
    private RoleS: RolePermissionService,
    private toast: ToasterService,
    private fb: UntypedFormBuilder,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { 
    this.saveForm = this.fb.group({
      role_name: ['', Validators.required],
      description: [''],
      administration: [''],
      caseCreate: [''],
      caseView: [''],
      caseEdit: [''],
      caseDelete: [''],
      caseDetailsCreate: [''],
      caseDetailsView: [''],
      caseDetailsEdit: [''],
      caseTimeLineView: [''],
      caseTaskCreate: [''],
      caseTaskView: [''],
      caseTaskEdit: [''],
      caseTaskDelete: [''],
      caseDocumentCreate: [''],
      caseDocumentView: [''],
      caseDocumentEdit: [''],
      caseDocumentDelete: [''],
      reportView: [''],
      petitionerCreate: [''],
      petitionerView: [''],
      petitionerEdit: [''],
      petitionerDelete: [''],
      respondentCreate: [''],
      respondentView: [''],
      respondentEdit: [''],
      respondentDelete: [''],
      counselCreate: [''],
      counselView: [''],
      counselEdit: [''],
      counselDelete: [''],
      userCreate: [''],
      userView: [''],
      userEdit: [''],
      userDelete: [''],
      roleCreate: [''],
      roleView: [''],
      roleEdit: [''],
      roleDelete: [''],
      masterCreate: [''],
      masterView: [''],
      masterEdit: [''],
      masterDelete: [''],
      resourceCreate: [''],
      resourceView: [''],
      resourceEdit: [''],
      resourceDelete: [''],
    });
  }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
    this.RoleS
      .EditRoles(this.id)
      .subscribe((data: any) => {
        this.EditData = data.updateData[0];
        this.onforminit(this.EditData)        
      })
  }
  get f() {
    if(this.saveForm) return this.saveForm?.controls;
    else return {};
  }

  onforminit(user: any) {
    this.saveForm.patchValue(user);
  }

  clickFunction() {
    this.isValidFormSubmitted = false;
    if (this.saveForm.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      this.saveForm.value.role_name = this.EditData.role_name
      this.RoleS
        .updateRoles(this.saveForm.value, this.id)
        .subscribe((data: any) => {

          this.toast.showSuccess(
            'Congratulation!, Data has been updated.'
          );
          this.router.navigate(['/dashboard/role-permission-list']);
        });
    }
  }

}
