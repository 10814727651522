import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-ce-list',
  templateUrl: './ce-list.component.html',
  styleUrls: ['./ce-list.component.css']
})
export class CEListComponent implements OnInit {


  getData: any;
  user: any;
  roledata: any;
  districts: any;

  constructor(
    private MasterS: MasterService,
    private toast: ToasterService,
  ) { }

  ngOnInit(): void {
    this.user = localStorage.getItem('userdata')
    this.roledata = JSON.parse(this.user).role;
    this.districts = JSON.parse(this.user).district
    this.viewdata();
  }

  viewdata() {
    this.MasterS.GetCEdata().subscribe((data: any) => {

      this.getData = data.resultData;
    });
  }

}
