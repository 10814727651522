<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <div class="lter b-b pt-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <!-- <h3 class="m-n font-medium">Dashboard</h3> -->
                    <h3 class="m-n font-medium"><i class="fa fa-angle-left" aria-hidden="true"></i> Update SE
                    </h3>
                </div>

            </div>
        </div>

        <div class="wrapper-md">
            <div class="panel b-a">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="head-title">
                                <h4>SE Information</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bb"></div>
                <div class="panel-body">
                    <form [formGroup]="saveForm" (ngSubmit)="clickFunction()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Organization / CE <span class="text-danger">*</span></label>

                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="sub_org_id">
                                        <option *ngFor="let data of getData" value={{data._id}}>{{data.sub_org_name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="f.sub_org_id.errors && isValidFormSubmitted"class="text-danger text-left mb-1">
                                        <div *ngIf="f.sub_org_id.errors">This field is required</div>
                                   </div> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">SE Name <span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter.."
                                        formControlName="se_name">
                                    <!-- <div *ngIf="f.se_name.errors && isValidFormSubmitted"class="text-danger text-left mb-1">
                                        <div *ngIf="f.se_name.errors">This field is required</div>
                                   </div> -->
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="">
                                    <div class="button_group_fotter">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>